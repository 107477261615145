export default {
  liveChat: 'Live Chat',
  callOrTextUsBody:
    'Call our support specialists if you have any questions or concerns.',
  callOrTextUsHeading: 'Call Us',
  callText: 'Call:',
  iconPhoneTitle: 'Call Us',
  iconMessageTitle: 'Chat',
  iconMailTitle1: 'Mail Us',
  mailUs: 'Mail Us',
  sendEmail: 'Send an Email',
  sendEmailText:
    "24 hours a day, you can submit your questions via email. We'll get back within one business day.",
  liveAgentChatTiming: '8 - 5 EST',
  liveAgentChatDays: 'MON - FRI'
}
