const formattingMixin = {
  methods: {
    /**
     * Function to format the phone number.
     * @param {String} phoneNumber
     */
    formatPhoneNumber(phoneNumber) {
      const formattedPhone = phoneNumber.replace('+1', '');
      const areaCode = formattedPhone.substring(0, 3);
      const middle = formattedPhone.substring(3, 6);
      const last = formattedPhone.substring(6, 10);

      if (formattedPhone.length > 6) {
        return `+1 ${areaCode}-${middle}-${last}`;
      } if (formattedPhone.length > 3) {
        return `+1 ${areaCode}-${middle}`;
      } if (formattedPhone.length > 0) {
        return `+1 ${areaCode}`;
      }
      return '';
    },
  },
};

export default formattingMixin;
