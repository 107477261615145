/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
import {
  getCLS, getFID, getLCP, getTTFB, getFCP,
} from 'web-vitals';
import AnalyticsService from '../services/analytics-service';
import globals from '../globals';

const initMixin = {
  data() {
    return {
      queryString: {},
      analyticsService: new AnalyticsService(),
      gaChecker: {},
      asmH1Timer: {},
      asmH1TimerCounter: 0,
    };
  },
  computed: {

  },
  mounted() {
    this.checkForCDNPathParm();
    this.checkForASMorSEparam();
    this.getAllVitals();
    this.asmH1Timer = setInterval(this.checkForAsmH1, 500);

    let otTimer = {};
    const otChecker = function() {
      if (window.OptanonWrapper && globals.getOnetrustActiveGroups()?.length) {
        clearInterval(otTimer);
        const isOpted = globals.checkIsOpted();

        window.OptanonWrapper = function() {
          if (isOpted !== globals.checkIsOpted()) {
            // eslint-disable-next-line no-self-assign
            window.location = window.location;
          }
        };
      }
    };
    otTimer = setInterval(otChecker, 500);
  },

  methods: {
    checkForCDNPathParm() {
      if (Object.prototype.hasOwnProperty.call(this.queryString, 'cdnpath')) {
        const cdnpathVal = this.queryString.cdnpath === 'localhost' ? '//localhost' : `//d2o3o2wreiygww.cloudfront.net/y/${this.queryString.cdnpath}`;
        const CookieDate = new Date();
        CookieDate.setFullYear(CookieDate.getFullYear() + 10);
        document.cookie = `cdnpath=${cdnpathVal};expires=${CookieDate.toUTCString()}; path=/`;
        delete this.queryString.cdnpath;

        if (Object.keys(this.queryString).length) {
          // if there are other query string params after cdnpath is deleted, add them back
          let newQueryString = '?';
          for (const prop in this.queryString) {
            if (this.queryString.hasOwnProperty(prop)) {
              newQueryString += `${prop}=${this.queryString[prop]}&`;
            }
          }
          window.location.search = newQueryString.slice(0, -1);
        } else {
          window.location = window.location.href.split('?')[0];
        }
      }
    },
    checkForASMorSEparam() {
      const isSmartEdit = window.location.href.indexOf('cmsTicketId') > -1;
      if (isSmartEdit) {
        const allResponsiveScript = document.createElement('script');
        const targetScriptTag = document.getElementsByTagName('HEAD');
        allResponsiveScript.setAttribute('type', 'text/javascript');
        allResponsiveScript.setAttribute('src', '/gp/wro/all_responsive.js');
        allResponsiveScript.onload = function() {
          const addonsResponsiveScript = document.createElement('script');
          addonsResponsiveScript.setAttribute('type', 'text/javascript');
          addonsResponsiveScript.setAttribute('src', '/gp/wro/addons_responsive.js');
          if (targetScriptTag && targetScriptTag.length > 0) { targetScriptTag[0].appendChild(addonsResponsiveScript); }
        };
        if (targetScriptTag && targetScriptTag.length > 0) { targetScriptTag[0].appendChild(allResponsiveScript); }
      }
    },
    sendToGoogleAnalytics({ name, delta, id }) {
      // Assumes the global `ga()` function exists, see:
      // https://developers.google.com/analytics/devguides/collection/analyticsjs

      window.ga.getAll()[0].send('event', {
        eventCategory: 'Web Vitals',
        eventAction: name,
        // The `id` value will be unique to the current page load. When sending
        // multiple values from the same page (e.g. for CLS), Google Analytics can
        // compute a total by grouping on this ID (note: requires `eventLabel` to
        // be a dimension in your report).
        eventLabel: id,
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        // (note: increase the multiplier for greater precision if needed).
        eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
        // Use a non-interaction event to avoid affecting bounce rate.
        nonInteraction: true,
        // Use `sendBeacon()` if the browser supports it.
        transport: 'beacon',

        // OPTIONAL: any additional params or debug info here.
        // See: https://web.dev/debug-web-vitals-in-the-field/
        // dimension1: '...',
        // dimension2: '...',
        // ...
      });
    },

    getAllVitals() {
      if (window.ga && window.ga.loaded) {
        clearTimeout(this.gaChecker);
        getCLS(this.sendToGoogleAnalytics);
        getFID(this.sendToGoogleAnalytics);
        getLCP(this.sendToGoogleAnalytics);
        getFCP(this.sendToGoogleAnalytics);
        getTTFB(this.sendToGoogleAnalytics);
      } else {
        this.gaChecker = setTimeout(this.getAllVitals, 2000);
      }
    },

    checkForAsmH1() {
      const allH1s = document.querySelectorAll('h1');
      this.asmH1TimerCounter += 1;
      if (allH1s.length > 0) {
        Array.prototype.forEach.call(allH1s, (node) => {
          if (node.innerText === 'Login is unavailable in ASM.') { node.innerText = 'Log in to continue'; node.classList.add('text-center'); }
        });
        clearInterval(this.asmH1Timer);
      } else if (this.asmH1TimerCounter === 20) {
        clearInterval(this.asmH1Timer);
      }
    },
  },
  created() {
    const util = {
      parseQueryString: () => {
        if (window.location.search.length > 0) {
          const q = window.location.search.substring(1).split('&');
          if (q) {
            for (let i = 0; i < q.length; i += 1) {
              const P = q[i].split('=');
              const key = decodeURIComponent(P[0]);
              const value = decodeURIComponent(P[1]);
              this.queryString[key.toLowerCase()] = value;
            }
          }
        }
      },
    };
    util.parseQueryString();
  },
};

export default initMixin;
