const searchBrowse = {
  comparePanel: {
    panelMessage1: 'Compare up to ',
    panelMessage2: 'products',
    primaryButtonText: 'View Details',
    compareProductsA11y: 'compare products',
    label: {
      remove: 'Remove',
    },
    iconXCircleTitle: 'Error',
    iconXTitle: 'Close',
  },
  autoSuggest: {
    heading: 'Suggested Results',
    viewAllLinkText: 'View all',
    iconXTitle: 'Close',
    iconSearchTitle: 'Search',
    allySearchLabel: 'Search For Product',
  },
  contentSearch: {
    headingPartA: 'Search Result for',
    subHeadingPartA: 'Your search for',
    subHeadingPartB: 'returned',
    subHeadingPartC: 'results',
    allResults: 'All Results',
  },
  productTile: {
    label: {
      asmHeading: 'ASM Alert',
      asmMessage: 'Please click Start Session button to start guest session.',
      findAStore: 'Where To Buy',
      productCode: 'ITEM ID',
      productCodeA11y: 'Item ID:',
      chooseOption: 'Choose Option',
      a11ySlashedPrice: 'Slashed Price',
      a11yCurrentPrice: 'Current Price',
    },
  },
   searchResult: {
    clearAllText: 'Clear All',
    countUnit: 'Items',
    searchResults: 'Search results for',
    refineResults: 'Refine Results',
    noCategoryResuts: {
      heading: 'Oops, something went wrong!',
      message1:
        'No Products are available for Category, Please select the other Category',
      message2:
        'If you need immediate assistance wirth anything, please contact GPXpress 877-GPXPRESS(479-7737).',
      message3:
        'Sorry, we could not find any results that fit this criteria.',
    },
    noResultMessage:
      'Sorry, we could not find any results that match your search. Please check your spelling and try a new search.',
    iconXTitle: 'Close',
    averageRatingLabel: 'Average Rating',
    totalReviewsLabel: 'Total Reviews',
    contentTab: 'Explore AngelSoft.com',
    products: 'Products',
  },
};

export default searchBrowse;
