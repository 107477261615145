var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "section",
        {
          staticClass: "flyout-banner",
          class: {
            "flyout-checkout-position": _vm.isCheckout,
            "flyout-position": !_vm.isCheckout
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "flyout-container d-flex justify-content-between",
              class: _vm.flyoutObject.status
            },
            [
              _c(
                "div",
                {
                  staticClass: "flyout-wrapper d-flex justify-content-between"
                },
                [
                  _c("div", { staticClass: "d-flex flex-row" }, [
                    _vm.flyoutObject.status === _vm.flyoutStatus.success
                      ? _c("span", {
                          staticClass:
                            "icon-check success-icon-color flyout-icons",
                          attrs: { title: _vm.i18n.iconSuccessTitle }
                        })
                      : _c("span", {
                          staticClass:
                            "icon-alert-triangle error-icon-color flyout-icons",
                          attrs: { title: _vm.i18n.iconAlertTitle }
                        }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "text-container mx-xs-2",
                      attrs: {
                        "aria-live": "polite",
                        role: "alert",
                        "aria-atomic": "true"
                      },
                      domProps: { innerHTML: _vm._s(_vm.flyoutObject.message) }
                    })
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "icon-x flyout-icons",
                    class: {
                      "success-icon-color":
                        _vm.flyoutObject.status === _vm.flyoutStatus.success,
                      "error-icon-color":
                        _vm.flyoutObject.status === _vm.flyoutStatus.error
                    },
                    attrs: {
                      title: _vm.i18n.iconCloseTitle,
                      role: "button",
                      tabindex: "0",
                      "aria-label": _vm.i18n.iconCloseTitle
                    },
                    on: {
                      click: function($event) {
                        _vm.dismissFlyout($event)
                      },
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        _vm.dismissFlyout($event)
                      }
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-17cde5fb", { render: render, staticRenderFns: staticRenderFns })
  }
}