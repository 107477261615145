// Global file to add Enums

/**
 * @enum {Object} ProductAvailability
 * product availability status
 */
const ProductAvailability = {
  OUT_OF_STOCK: 'outOfStock',
  IN_STOCK: 'inStock',
  LOW_STOCK: 'lowStock',
  OBSOLETE: 'OBSOLETE',
  PREDISCONTINUED: 'PREDISCONTINUED',
  COMING_SOON: 'COMING_SOON',
  NO_STOCK: 'noStock',
  ACTIVE: 'ACTIVE',
  OFFLINE: 'unavailable',
  MAX_PURCHASABLE_QTY_ERROR_CODE: '197',
  LOW_STOCK_ERROR_CODE: '198',
};
Object.freeze(ProductAvailability);

/**
 * @enum {Object} subscriptionAvailability
 * subscribed product availability status
 */
const subscriptionAvailability = {
  OUT_OF_STOCK: 'OutofStock',
  PAYMENT_FAILURE: 'PaymentFailure',
  ACTIVE: 'ACTIVE',
};
Object.freeze(subscriptionAvailability);

/**
 * @enum {Object} subscriptionStatus
 * subscription status
 */
const subscriptionStatus = {
  outOfStock: 'Out of Stock',
  paymentFailure: 'Payment Failure',
  active: 'Active',
};
Object.freeze(subscriptionStatus);

/**
 * @enum {Object} MaterialStatus
 * material status of product
 */
const MaterialStatus = {
  ACTIVE_PRODUCT: 'ACTIVE',
};
Object.freeze(MaterialStatus);

/**
 * @enum {Object} PdpCarouselConfig
 * PDP carousel properties
 */
const PdpCarouselConfig = {
  CYCLE: 'false',
  AUTO_START: 'false',
  CAROUSEL_NAME: 'pdpProductCarousel',
};
Object.freeze(PdpCarouselConfig);

/**
 * @enum {Object} footerLinkTypes
 * types of footer links
 */
const footerLinkTypes = {
  SIMPLE_TEXT: 'text',
  SIMPLE_TEL: 'tel',
  SIMPLE_MAIL: 'mailto',
};
Object.freeze(footerLinkTypes);

/**
 * @enum {Object} duplicateMailErrorCode
 * error code for duplicate mail
 */
const duplicateMailErrorCode = {
  DUP_EMAIL_ERROR_CODE: '100',
};
Object.freeze(duplicateMailErrorCode);

/**
 * @enum {Object} noEmailErrorCode
 * error code for no mail
 */
const noEmailErrorCode = {
  NO_EMAIL_ERROR_CODE: '1000',
};
Object.freeze(noEmailErrorCode);

/**
 * @enum {Object} UserRoles
 * types of user roles
 */
const UserRoles = {
  ADMINISTRATORS: 'administrators',
  BUYERS: 'buyers',
  ADMIN: 'b2badmingroup',
  CUSTOMER: 'b2bcustomergroup',
  DISPLAY_ADMIN: 'Admin',
  DISPLAY_BUYER: 'Buyer',
};
Object.freeze(UserRoles);

/**
 * @enum {Object} UserDetails
 * user details properties
 */
const UserDetails = {
  USER_GROUP_ID: 'ugid',
  USER_ID: 'uid',
};
Object.freeze(UserDetails);

/**
 * @enum {Object} UnitDetails
 * unit details properties
 */
const UnitDetails = {
  UNIT_ID: 'unitid',
};
Object.freeze(UnitDetails);

/**
 * @enum {Object} imageFormat
 * format of image
 */
const imageFormat = {
  PRODUCT: 'product',
  THUMBNAIL: 'thumbnail',
};
Object.freeze(imageFormat);

/**
 * @enum {Object} cartTypes
 * types of cart
 */
const cartTypes = {
  FULL: 'FULL',
  DEFAULT: 'DEFAULT',
  BASIC: 'BASIC',
  SUMMARY: 'SUMMARY',
};
Object.freeze(cartTypes);

/**
 * @enum {Object} singleDigitDate
 * single digit dates
 */
const singleDigitDate = ['01', '02', '03', '04', '05', '06', '07', '08', '09'];
Object.freeze(singleDigitDate);

/**
 * @enum {Object} month
 * values of month
 */
const month = {
  options: [{
    value: '01',
    label: '01',
  },
  {
    value: '02',
    label: '02',
  },
  {
    value: '03',
    label: '03',
  },
  {
    value: '04',
    label: '04',
  },
  {
    value: '05',
    label: '05',
  },
  {
    value: '06',
    label: '06',
  },
  {
    value: '07',
    label: '07',
  },
  {
    value: '08',
    label: '08',
  },
  {
    value: '09',
    label: '09',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '11',
    label: '11',
  },
  {
    value: '12',
    label: '12',
  },
  ],
};
Object.freeze(month);

const monthLiterals = {
  options: [{
    value: '01',
    label: 'January',
  },
  {
    value: '02',
    label: 'February',
  },
  {
    value: '03',
    label: 'March',
  },
  {
    value: '04',
    label: 'April',
  },
  {
    value: '05',
    label: 'May',
  },
  {
    value: '06',
    label: 'June',
  },
  {
    value: '07',
    label: 'July',
  },
  {
    value: '08',
    label: 'August',
  },
  {
    value: '09',
    label: 'September',
  },
  {
    value: '10',
    label: 'October',
  },
  {
    value: '11',
    label: 'November',
  },
  {
    value: '12',
    label: 'December',
  },
  ],
};
Object.freeze(monthLiterals);

/**
 * @enum {Object} genderList
 * types of gender
 */
const genderList = [{
  label: 'Male',
  value: 'Male',
},
{
  label: 'Female',
  value: 'Female',
},
{
  label: 'Prefer not to identify',
  value: 'PreferNotToIdentify',
},
{
  label: 'Select Gender',
  value: '',
},
];
Object.freeze(genderList);

/**
 * @enum {Object} PreferNotToIdentify
 * other gender type
 */
const PreferNotToIdentify = {
  otherGender: 'PreferNotToIdentify',
};
Object.freeze(PreferNotToIdentify);

/**
 * @enum {Object} dateSuffix
 * types of date suffixes
 */
const dateSuffix = {
  first: 'st',
  second: 'nd',
  third: 'rd',
  other: 'th',
};
Object.freeze(dateSuffix);

/**
 * @enum {Object} monthCollection1
 * month collection type 1
 */
const monthCollection1 = ['01', '03', '05', '07', '08', '10', '12'];
Object.freeze(monthCollection1);

/**
 * @enum {Object} monthCollection2
 * month collection type 2
 */
const monthCollection2 = [
  '01',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];
Object.freeze(monthCollection2);

/**
 * @enum {Object} monthNames
 * name of months
 */
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
Object.freeze(monthNames);

/**
 * @enum {Object} country
 * country names
 */
const country = {
  options: [{
    label: 'US',
    value: 'US',
  },
  {
    label: 'CANADA',
    value: 'CANADA',
  },
  ],
};
Object.freeze(country);

/**
 * @enum {Object} state
 * state names
 */
const state = {
  options: [{
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District of Columbia',
    value: 'DC',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansa',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Lousiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
  ],
};
Object.freeze(state);

/**
 * @enum {Object} year
 * year available
 */
const year = {
  options: [{
    value: '2018',
    label: '2018',
  },
  {
    value: '2019',
    label: '2019',
  },
  {
    value: '2020',
    label: '2020',
  },
  {
    value: '2021',
    label: '2021',
  },
  {
    value: '2022',
    label: '2022',
  },
  {
    value: '2023',
    label: '2023',
  },
  {
    value: '2024',
    label: '2024',
  },
  {
    value: '2025',
    label: '2026',
  },
  {
    value: '2027',
    label: '2027',
  },
  {
    value: '2028',
    label: '2028',
  },
  ],
};
Object.freeze(year);

/**
 * @enum {Object} businessUnitLevel
 * type of business unit level
 */
const businessUnitLevel = {
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
};
Object.freeze(businessUnitLevel);

/**
 * @enum {Object} socialConnect
 * social connect properties
 */
const socialConnect = {
  password: 'password',
};
Object.freeze(socialConnect);

/**
 * @enum {Object} countryList
 * list of countries
 */
const countryList = {
  options: [{
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  ],
};
Object.freeze(countryList);

/**
 * @enum {Object} userStates
 * status of user's application
 */
const userStates = {
  pending: 'PENDING',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  underReview: 'Under Review',
  status: 'STATUS',
  l1: 'L1',
  l2: 'L2',
  l3: 'L3',
};
Object.freeze(userStates);

/**
 * @enum {Object} cardNames
 * names of payment cards
 */
const cardNames = {
  visa: 'Visa',
  masterCard: 'Mastercard',
  discover: 'Discover',
  americanExpress: 'American Express',
  diner: "Diner's Club",
  googlePay: 'Google Pay',
};
Object.freeze(cardNames);

/**
 * @enum {Object} approvalDefaultStatus
 * default status of approval
 */
const approvalDefaultStatus = {
  pendingApproval: 'PENDING_APPROVAL',
};
Object.freeze(approvalDefaultStatus);

/**
 * @enum {Object} approvalDetailStatus
 * detail status of approval
 */
const approvalDetailStatus = {
  created: 'CREATED',
  rejected: 'REJECTED',
};
Object.freeze(approvalDetailStatus);

/**
 * @enum {Object} flyoutStatus
 * status of flyout
 */
const flyoutStatus = {
  success: 'success',
  error: 'error',
  alert: 'alert',
};
Object.freeze(flyoutStatus);

/**
 * @enum {Object} backgroundColor
 * background color
 */
const backgroundColor = {
  transparent: 'transparent',
};
Object.freeze(backgroundColor);

/**
 * @enum {Object} raysImages
 * rays image paths
 */
const raysImages = {
  desktop: './_ui/responsive/common/dist/static/assets/images/rays_D.png',
  tablet: './_ui/responsive/common/dist/static/assets/images/rays_T.png',
  mobile: './_ui/responsive/common/dist/static/assets/images/rays_M.png',
  calssname: 'rays',
};
Object.freeze(raysImages);
/**
 * @enum {Object} myAccountAddressStatus
 * status of address in my account section
 */
const myAccountAddressStatus = {
  disabled: 'DISABLED',
  active: 'ACTIVE',
  pending: 'PENDING',
  pendingbyadmin: 'PENDINGBYADMIN',
  pendingbygp: 'PENDINGBYGP',
};
Object.freeze(myAccountAddressStatus);

/**
 * @enum {Object} otherCountry
 * other country details
 */
const otherCountry = {
  label: 'Others',
  value: 'other',
};
Object.freeze(otherCountry);

/**
 * @enum {Object} defaultCountry
 * default country details
 */
const defaultCountry = {
  label: 'United States of America',
  value: 'US',
};
Object.freeze(defaultCountry);

/**
 * @enum {Object} defaultState
 * default country details
 */
const defaultState = {
  label: 'Select Option',
  value: '',
};
Object.freeze(defaultState);

/**
 * @enum {Object} defaultGender
 * default gender details
 */
const defaultGender = {
  label: 'Select Gender',
  value: '',
};
Object.freeze(defaultGender);

/**
 * @enum {Object} defaultMonth
 * default month details
 */
const defaultMonth = {
  label: 'Month',
  value: '',
};
Object.freeze(defaultMonth);

/**
 * @enum {Object} defaultDate
 * default date details
 */
const defaultDate = {
  label: 'Date',
  value: '',
};
Object.freeze(defaultDate);

/**
 * @enum {Object} pageType
 * type of pages
 */
const pageType = {
  cart: 'cart',
  checkout: 'checkout',
  orderConfirmation: 'orderConfirmation',
  orderDetails: 'orderDetails',
  orderApprovalDetails: 'orderApprovalDetails',
  page: 'page',
};
Object.freeze(pageType);

/**
 * @enum {Object} order
 * type of order
 */
const order = {
  ascending: 'asc',
  descending: 'dsc',
  orderId: 'orderId',
};
Object.freeze(order);

/**
 * @enum {Object} addToCartStatus
 * status of add to cart
 */
const addToCartStatus = {
  failure: 'FAILURE',
};
Object.freeze(addToCartStatus);

/**
 * @enum {Object} surveyQuestionType
 * type of survey question
 */
const surveyQuestionType = {
  select: 'SELECT',
  radio: 'RADIO',
  textField: 'TEXT_FIELD',
};
Object.freeze(surveyQuestionType);

/**
 * @enum {Object} discount
 * type of discount
 */
const discount = {
  discountZero: '0.00',
};
Object.freeze(discount);

/**
 * @enum {Object} brandNames
 * name of brands
 */
const brandNames = {
  gppro: 'gppro',
  vanityfair: 'vanityfairnapkins',
  b2bwhitelabel: 'b2bwhitelabel',
  b2cwhitelabel: 'b2cwhitelabel',
  brawny: 'brawny',
  innovia: 'innovia',
  sparkle: 'sparkle',
  estores: 'estores',
  dixieretail: 'dixieretail',
  angelsoft: 'angelsoft',
  aria: 'aria',
  quiltedNorthern: 'quiltedNorthern',
};
Object.freeze(brandNames);

const trackJsBrandNames = {
  gppro: 'gp-pro',
  vanityfair: 'vanity-fair',
  brawny: 'brawny',
  innovia: 'innovia',
  sparkle: 'sparkle',
  estores: 'estores',
  dixieretail: 'dixie-retail',
  angelsoft: 'angelsoft',
  aria: 'aria',
  quiltedNorthern: 'quilted-northern',
};
Object.freeze(trackJsBrandNames);
/**
 * @enum {Object} leftNavigation
 * left navigation values
 */
const leftNavigation = {
  orderHistory: 'Order History',
  yourCompany: 'YOUR COMPANY',
  yourAccount: 'YOUR ACCOUNT',
};
Object.freeze(leftNavigation);

/**
 * @enum {Object} favorites
 * favorites list name
 */
const favorites = {
  favorites: 'favorites',
};
Object.freeze(favorites);

/**
 * @enum {Object} brandValues
 * brandValues will be used only to display for ui puproses, brandnames is the value of siteId.
 */
const brandValues = {
  dixieretail: 'dixieretail',
  gppro: 'GP Pro',
  vanityfair: 'Vanity Fair',
  sparkle: 'sparkle',
  brawny: 'brawny',
  angelsoft: 'angelsoft',
  quiltedNorthern: 'quiltedNorthern',
  mdtissue: 'mdtissue',

};
Object.freeze(brandValues);

/**
 * @enum {Object} httpStatusCodes
 * status code for http
 */
const httpStatusCodes = {
  unauthorized: 401,
};
Object.freeze(httpStatusCodes);

/**
 * @enum {Object} fileDownload
 * downloaded file name
 */
const fileDownload = {
  csv: 'Sample.csv',
};
Object.freeze(fileDownload);

/**
 * @enum {Object} logoutFlowCookie
 * cookie for logout flow
 */
const logoutFlowCookie = {
  logoutFlow: 'logoutFlow',
  emailUpdate: 'emailUpdate',
  socialConnect: 'socialConnect',
  socialDisconnect: 'socialDisconnect',
};
Object.freeze(logoutFlowCookie);

/**
 * @enum {Object} booleanFlags
 * boolean flags for shipping address and contact us
 */
const booleanFlags = {
  isShippingAddress: 'isShippingAddress',
  isContactUs: 'isContactUs',
};
Object.freeze(booleanFlags);

/**
 * @enum {Object} avsStatusCodes
 * status codes for avs
 */
const avsStatusCodes = {
  failure: 'S1000',
  success: 'S0000',
};
Object.freeze(avsStatusCodes);

/**
 * @enum {Object} referenceTypes
 * reference types of product
 */
const referenceTypes = {
  similar: 'SIMILAR',
  refills: 'RELATEDPRODUCTSMODAL',
};
Object.freeze(referenceTypes);

/**
 * @enum {Object} paymentTypes
 * types of payment
 */
const paymentTypes = {
  credit: 'Credit/Debit Card',
  paypal: 'PayPal',
  creditCard: 'CreditCard',
  applePay: 'Apple Pay',
  googlePay: 'Google Pay',
};
Object.freeze(paymentTypes);

/**
 * @enum {Object} paymentStatus
 * status of payment
 */
const paymentStatus = {
  accept: 'ACCEPT',
  decline: 'DECLINE',
  error: 'ERROR',
  paymentDecision: 'paymentDecission',
};
Object.freeze(paymentStatus);

/**
 * @enum {Object} paypalType
 * type of paypal
 */
const paypalType = {
  credit: 'credit',
  nonCredit: 'non-credit',
};
Object.freeze(paypalType);

/**
 * @enum {Object} product3DHeightBreakPoints
 * product 3D height break points
 */
const product3DHeightBreakPoints = {
  desktop: 448,
  tablet: 288,
  mobile: 248,
};
Object.freeze(product3DHeightBreakPoints);

/**
 * @enum {Object} facetName
 * name of facets
 */
const facetName = {
  ratingLabel: 'Ratings',
  availableForLocationLabel: 'Available For Location',
};
Object.freeze(facetName);

/**
 * @enum {Object} paypalResponseStatus
 * response status of paypal response
 */
const paypalResponseStatus = {
  error: 'ERROR',
};
Object.freeze(paypalResponseStatus);

/**
 * @enum {Object} cookies
 * cookie names
 */
const cookies = {
  soldTo: 'soldTo',
  userId: 'userId',
  pdfDetails: 'pdfDetails',
  selectedBulkProducts: 'selectedBulkProducts',
  guestListUid: 'guestListUid',
  compareCookie: 'CompareCookie',
  subscrCartId: 'subscrCartId',
  marketingModal: 'marketingModal',
};
Object.freeze(cookies);

/**
 * @enum {Object} googleErrorResponse
 * error response of Google
 */
const googleErrorResponse = {
  popUpClosedError: 'popup_closed_by_user',
};
Object.freeze(googleErrorResponse);

/**
 * @enum {Object} keyCode
 * key code values
 */
const keyCode = {
  enter: 13,
  up: 38,
  down: 40,
  A: 65,
  Z: 90,
  0: 48,
  9: 57,
  number0: 96,
  number9: 105,
};
Object.freeze(keyCode);

/**
 * @enum {Object} relatedCategories
 * Categories of Related products
 */
const relatedCategories = {
  ACCESSORIES: 'Accessories',
  ALSOCONSIDER: 'Also Consider',
  BONUSPACK: 'Bonus Pack',
  BOWLSDISHES: 'Bowls/ Dishes',
  BRICK: 'Brick',
  CASE: 'Case',
  CHIMNEY: 'Chimney',
  COPROMOTE: 'coPromote',
  CUPSCONTAINERS: 'Cups/ Containers',
  DISPENSERKEYS: 'Dispenser Keys',
  DISPENSERPARTS: 'Dispenser Parts',
  DISPENSERS: 'Dispensers',
  DIXIEDISPENSER: 'Dixie Dispenser',
  INDIVIDUALPACK: 'Individual Pack',
  LIDS: 'Lids',
  MACHINE: 'Machine',
  MACHINEPARTS: 'Machine Parts',
  MERCHANDISINGVEHICLE: 'Merchandising Vehicle',
  PLATES: 'Plates',
  PRODUCTFAMILY: 'Product Family',
  QSU: 'QSU',
  REFILLS: 'Refills',
  REGULARPACK: 'Regular Pack',
  SIMILAR: 'Similar',
  TRADEUP: 'Trade Up',
  TRUCKHIGH: 'Truck High',
  MIXEDPALLETCOMPONENTS: 'Mixed Pallet Components',
  DOWNSELL: 'Downsell',
  UPSELLING: 'Upsell',
};
Object.freeze(relatedCategories);

/**
 * @enum {Object} contentType
 * type of content
 */
const contentType = {
  page: 'page',
};
Object.freeze(contentType);

/**
 * @enum {Object} generatePdf
 * PDF generation properties
 */
const generatePdf = {
  coverPageBlob: 'coverPageBlob',
  sustainabilityBlob: 'sustainabilityBlob',
  imgBlob: 'imgBlob',
  WASTE_REDUCING: 'WASTE_REDUCING',
  ECO_LOGO: 'ECO_LOGO',
  GREEN_SEAL: 'GREEN_SEAL',
  USGBC_LEED: 'USGBC_LEED',
  EPA: 'EPA',
  SFI: 'SFI',
  CMA: 'CMA',
  PROCESS_CHLORINE_FREE: 'PROCESS_CHLORINE_FREE',
  BIO_PREFERRED: 'BIO_PREFERRED',
  DFE: 'DFE',
  BPI: 'BPI',
  WHC: 'WHC',
  FSC: 'FSC',
  ECC: 'ECC',
  SAFER_CHOICE: 'SAFER_CHOICE',
  HOW_2_RECYCLE: 'HOW_2_RECYCLE',
  CUSTOMIZABLE: 'CUSTOMIZABLE',
  KOLO: 'KOLO',
  RPA: 'RPA',
  TUV_HOME_COMPOST: 'TUV_HOME_COMPOST',
  NITRILE_COMPATIBLE: 'NITRILE_COMPATIBLE',
  ELEMENTS: 'ELEMENTS',
  HACCP: 'HACCP',
  productDetails: 'Product Details',
  fullDetails: 'Full details',
  soldTo555555: '555555',
  size: 'Size',
};
Object.freeze(generatePdf);

/**
 * @enum {Object} pdpVariantTypes
 * variant types in PDP
 */
const pdpVariantTypes = {
  color: 'Color',
  count: 'Count',
  scent: 'Scent',
  size: 'Size',
};
Object.freeze(pdpVariantTypes);

/**
 * @enum {Object} pdfColors
 * color values for PDF
 */
const pdfColors = {
  barColor: '#000000',
  headlineColor: '#000000',
};
Object.freeze(pdfColors);

/**
 * @enum {Object} supportTicketOrder
 * order for support ticket
 */
const supportTicketOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
  ascClass: 'sorting_asc',
  descClass: 'sorting_desc',
  sortingClass: 'sorting',
  ticket: 'Ticket',
};
Object.freeze(supportTicketOrder);

/**
 * @enum {Object} CJEventParms
 * CJ Event Variables
 */
const CJEventParms = {
  containerTagId: 29756,
  CID: 1550957,
  TYPE: 407506,
};
Object.freeze(CJEventParms);

/**
 * @enum {Object} socialShareSize
 * Social Share size
 */
const socialShareSize = {
  default: 32,
  small: 24,
};
Object.freeze(socialShareSize);

/**
 * @enum {Object} placeOrder
 * place order details
 */
const placeOrder = {
  cart: 'cart',
  error: 'error',
  empty: 'empty',
};
Object.freeze(placeOrder);

/**
 * @enum {Object} ApplePay
 * Apple Pay properties
 */
const ApplePay = {
  APIVersion: 2,
  countryCode: 'US',
  currencyCode: 'USD',
  supportedNetworks: ['amex', 'discover', 'jcb', 'masterCard', 'visa'],
  merchantCapabilities: ['supports3DS'],
  requiredBillingContactFields: ['postalAddress', 'name'],
  requiredShippingContactFields: ['postalAddress', 'name', 'email', 'phone'],
  labels: {
    cartSubtotal: 'Cart Subtotal',
    shipping: 'Shipping',
    tax: 'Tax',
    paymentType: 'final',
  },
};
Object.freeze(ApplePay);

/**
 * @enum {Object} GooglePay
 * Google Pay properties
 */
const GooglePay = {
  labels: {
    subtotalLabel: 'Subtotal',
    subtotalType: 'SUBTOTAL',
    taxLabel: 'Tax',
    taxType: 'TAX',
    shippingLabel: 'Shipping',
    lineItem: 'LINE_ITEM',
    totalLabel: 'Total',
    paymentType: 'final',
    checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',
    allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
  },
};
Object.freeze(GooglePay);

/**
 * @enum {Object} iriProductId
 * IRI service params to detect its own category id
 */
const iriProductId = {
  vanityFair: 'VANITYFAIR',
  sparkle: 'SPARKLE',
  brawny: 'BRAWNYTOWEL',
  angelsoft: 'ANG',
  dixieretail: 'DIXIEALL',
  quiltedNorthern: 'QN',
  allMDTissue: 'MD',
};
Object.freeze(iriProductId);

const pages = {
  wishList: 'WISHLIST',
  plp: 'PLP',
  pdp: 'PDP',
};
Object.freeze(pages);

export {
  ProductAvailability,
  subscriptionAvailability,
  subscriptionStatus,
  MaterialStatus,
  PdpCarouselConfig,
  UserRoles,
  UserDetails,
  UnitDetails,
  cartTypes,
  month,
  monthLiterals,
  genderList,
  monthCollection1,
  monthCollection2,
  monthNames,
  dateSuffix,
  country,
  state,
  year,
  businessUnitLevel,
  socialConnect,
  countryList,
  userStates,
  cardNames,
  approvalDefaultStatus,
  approvalDetailStatus,
  flyoutStatus,
  backgroundColor,
  raysImages,
  myAccountAddressStatus,
  otherCountry,
  defaultCountry,
  defaultState,
  defaultGender,
  defaultMonth,
  defaultDate,
  pageType,
  order,
  addToCartStatus,
  surveyQuestionType,
  discount,
  brandNames,
  trackJsBrandNames,
  leftNavigation,
  favorites,
  imageFormat,
  brandValues,
  httpStatusCodes,
  fileDownload,
  logoutFlowCookie,
  booleanFlags,
  avsStatusCodes,
  referenceTypes,
  paymentTypes,
  paymentStatus,
  paypalType,
  product3DHeightBreakPoints,
  facetName,
  paypalResponseStatus,
  cookies,
  googleErrorResponse,
  keyCode,
  relatedCategories,
  contentType,
  generatePdf,
  PreferNotToIdentify,
  pdpVariantTypes,
  pdfColors,
  supportTicketOrder,
  singleDigitDate,
  CJEventParms,
  socialShareSize,
  footerLinkTypes,
  ApplePay,
  GooglePay,
  placeOrder,
  duplicateMailErrorCode,
  noEmailErrorCode,
  iriProductId,
  pages,
};
