var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "vx-spinner" }, [
    _vm.spinnerVisible
      ? _c(
          "div",
          {
            staticClass: "spinner-container",
            class: { fullscreen: _vm.fullScreen }
          },
          [
            _c("img", {
              staticClass: "spinner",
              attrs: { alt: "Loading", src: _vm.image, title: "Loading" }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c89acbca", { render: render, staticRenderFns: staticRenderFns })
  }
}