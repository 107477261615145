/**
 * Google Analytics Service provides GTM enhanced ecommerce events.
 */
class GoogleAnalyticsService {
  /* global dataLayer */

  /**
   * Track Data Object passed
   * @param  {object}   dataObj data to track
   * @return {object}          null
   */
  trackDataObject = (dataObj) => {
    try {
      if (dataLayer) {
        dataLayer.push(dataObj);
      }
    } catch (e) {
      if (dataObj.eventCallback) { dataObj.eventCallback.apply(); }
    }
  };

  /**
   * Track search results for GTM enhanced ecommerce tracking
   * @param  {object}   dataObj contains search term and total result
   * @return {object}          null
   */
  trackSearchResults = (dataObj, callback) => {
    const ecommerceObject = {
      event: 'searchResults',
      ecommerce: {
        search: dataObj,
      },
    };
    if (callback) {
      ecommerceObject.eventCallback = callback;
    }
    this.trackDataObject(ecommerceObject);
  };

  /**
   * Track products pruchased for GTM enhanced ecommerce tracking
   * @param  {object}   dataObj contains details of product purchased
   * @return {object}          null
   */
  trackProductsPurchased = (dataObj, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'purchase',
        ecommerce: {
          purchase: dataObj,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track products pruchased for GA4 GTM enhanced ecommerce tracking
   * @param  {object}   dataObj contains details of product purchased
   * @return {object}          null
   */
  trackProductsPurchasedGA4 = (dataObj, transactionId, affiliationValue, totalValue, taxAmount, shippingCost, currencyIso, couponCode, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'purchase',
        ecommerce: {
          transaction_id: transactionId,
          affiliation: affiliationValue,
          value: totalValue,
          tax: taxAmount,
          shipping: shippingCost,
          currency: currencyIso,
          coupon: couponCode,
          items: dataObj.products,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track product impressions for GTM enhanced ecommerce tracking
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackImpressions = (dataObj, currencyCode, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'productImpressions',
        ecommerce: {
          currencyCode,
          impressions: dataObj,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track product details impressions results for GTM enhanced ecommerce tracking
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackDetailImpressions = (dataObj, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'productDetail',
        ecommerce: {
          detail: {
            actionField: {
              list: '',
            },
            products: dataObj,
          },
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track product add to cart for GTM enhanced ecommerce tracking
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
  trackAddToCart = (dataObj, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'addToCart',
        ecommerce: {
          add: dataObj,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track View Product Detail Page GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackViewItemGA4 = (dataObj, currencyCode, price, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'view_item',
        ecommerce: {
          currency: currencyCode,
          value: price,
          items: dataObj,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track product add to cart for GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
  trackAddToCartGA4 = (dataObj, currencyCode, price, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'add_to_cart',
        ecommerce: {
          currency: currencyCode,
          value: price,
          items: dataObj.products,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track select item for GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
    trackSelectItemGA4 = (dataObj, currencyCode, price, callback) => {
      const ecommerceObject = {
        event: 'select_item',
        ecommerce: {
          currency: currencyCode,
          value: price,
          items: dataObj.products,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    };

    /**
   * Track user login for GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
    trackLoginGA4 = (methodName, callback) => {
      setTimeout(() => {
        const ecommerceObject = {
          event: 'login',
          method: methodName,
        };
        if (callback) {
          ecommerceObject.eventCallback = callback;
        }
        this.trackDataObject(ecommerceObject);
      }, 1000);
    };

    /**
 * Track user registration for GTM enhanced ecommerce tracking - GA4
 * @param  {object}   dataObj contains details of product added to cart
 * @return {object}          null
 */
    trackSignupGA4 = (methodName, callback) => {
      setTimeout(() => {
        const ecommerceObject = {
          event: 'sign_up',
          method: methodName,
        };
        if (callback) {
          ecommerceObject.eventCallback = callback;
        }
        this.trackDataObject(ecommerceObject);
      }, 1000);
    };

    /**
   * Track search results for GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains search term and total result
   * @return {object}          null
   */
    trackSearchGA4 = (dataObj, callback) => {
      setTimeout(() => {
        const ecommerceObject = {
          event: 'search',
          search_term: dataObj,
        };
        if (callback) {
          ecommerceObject.eventCallback = callback;
        }
        this.trackDataObject(ecommerceObject);
      }, 1000);
    };

    /**
   * Track select content details for GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
    trackSelectContentGA4 = (contentType, contentId, callback) => {
      const ecommerceObject = {
        event: 'select_content',
        ecommerce: {
          content_type: contentType,
          content_id: contentId,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    };

    /**
 * Track share product details for GTM enhanced ecommerce tracking - GA4
 * @param  {object}   dataObj contains details of product added to cart
 * @return {object}          null
 */
      trackShareGA4 = (shareMethod, contentType, itemId, callback) => {
        const ecommerceObject = {
          event: 'share',
          ecommerce: {
            method: shareMethod,
            content_type: contentType,
            item_id: itemId,
          },
        };
        if (callback) {
          ecommerceObject.eventCallback = callback;
        }
        this.trackDataObject(ecommerceObject);
      };

/**
   * Track product add to wishlist for GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
trackAddToWishListGA4 = (dataObj, currencyCode, price, callback) => {
  setTimeout(() => {
    const ecommerceObject = {
      event: 'add_to_wishlist',
      ecommerce: {
        currency: currencyCode,
        value: price,
        items: dataObj.products,
      },
    };
    if (callback) {
      ecommerceObject.eventCallback = callback;
    }
    this.trackDataObject(ecommerceObject);
  }, 1000);
};

  /**
   * Track View cart Page GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackViewCartGA4 = (dataObj, currencyCode, price, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'view_cart',
        ecommerce: {
          currency: currencyCode,
          value: price,
          items: dataObj.products,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track remove from cart GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackRemoveFromCartGA4 = (dataObj, currencyCode, price, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'remove_from_cart',
        ecommerce: {
          currency: currencyCode,
          value: price,
          items: dataObj.products,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track begin checkout GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackBeginCheckoutGA4 = (dataObj, currencyCode, price, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'begin_checkout',
        ecommerce: {
          currency: currencyCode,
          value: price,
          items: dataObj.products,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track begin checkout GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackShippingGA4 = (dataObj, currencyCode, price, couponCode, shippingTier, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'add_shipping_info',
        ecommerce: {
          currency: currencyCode,
          value: price,
          coupon: couponCode,
          shipping_tier: shippingTier,
          items: dataObj.products,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track add payment info GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackAddPaymentInfoGA4 = (dataObj, currencyCode, price, couponCode, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'add_payment_info',
        ecommerce: {
          currency: currencyCode,
          value: price,
          coupon: couponCode,
          items: dataObj.products,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track view items list info GTM enhanced ecommerce tracking - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackViewItemsListGA4 = (dataObj, itemListId, itemListName, totalSearchResults, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'view_item_list',
        ecommerce: {
          item_list_id: itemListId,
          item_list_name: itemListName,
          search_results: totalSearchResults,
          items: dataObj.products,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track product cart removal for GTM enhanced ecommerce tracking
   * @param  {object}   dataObj contains details of product removed from cart
   * @return {object}          null
   */
  trackRemoveFromCart = (dataObj, callback) => {
    setTimeout(() => {
      const ecommerceObject = {
        event: 'removeFromCart',
        ecommerce: {
          remove: dataObj,
        },
      };
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }, 1000);
  };

  /**
   * Track checkout information for GTM enhanced ecommerce tracking
   * @param  {object}   dataObj contains details of product during checkout
   * @return {object}          null
   */
  trackCheckout = (dataObj, callback) => {
    if (dataObj) {
      setTimeout(() => {
        const ecommerceObject = {
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: {
                step: 1,
                option: 'Checkout Initialized',
              },
              products: dataObj,
            },
          },
        };
        if (callback) {
          ecommerceObject.eventCallback = callback;
        }
        this.trackDataObject(ecommerceObject);
      }, 1000);
    }
  };

  /**
   * Track marketing form post related information
   * @param  {object}   dataObj contains details of form post
   * @return {object}          null
   */
  trackMarketingFormPost = (dataObj, callback) => {
    if (dataObj) {
      const ecommerceObject = dataObj;
      if (callback) {
        ecommerceObject.eventCallback = callback;
      }
      this.trackDataObject(ecommerceObject);
    }
  };
}

export default GoogleAnalyticsService;
