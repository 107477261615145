/* eslint-disable no-throw-literal */
export default {
  name: 'vx-spinner',
  components: {},
  props: {
    fullScreen: Boolean,
    image: String,
    text: String,
  },
  data() {
    return {
      spinnerVisible: false,
      spinnerTimer: null,
      spinnerCount: 0,
    };
  },
  computed: {

  },
  methods: {
    /**
     * Used for showing the spinner
     */
    showSpinner(duration, errorFunc) {
      this.spinnerVisible = true;
      this.spinnerCount += 1;
      if (duration) {
        this.spinnerTimer = setTimeout(() => { this.hideSpinner(errorFunc); }, duration);
      }
    },
    /**
     * Used for hiding the spinenr
     */
    hideSpinner(errorFunc) {
      this.spinnerVisible = false;
      this.spinnerCount -= 1;
      if (this.spinnerTimer) {
        clearTimeout(this.spinnerTimer);
        this.spinnerTimer = null;
      }
      if (errorFunc) {
        errorFunc();
        throw `Spinner exceeded timeout duration. Hiding timer and running function: ${errorFunc.name}`;
      }
    },
  },
};
