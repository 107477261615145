/* eslint-disable no-console */
/* eslint-disable no-eval */
/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable one-var */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable default-case */
/* eslint-disable object-shorthand */
import Vue from 'vue';
import globals from '../globals';

// import brawnyGTA from '../../../brawny/components/brawny-gta/brawny-gta.vue';

const vxMysteryReferral = () => import(/* webpackChunkName: "mystery-campaign" */ '../../../marketingpages/components/vx-mystery-referral/vx-mystery-referral.vue');
const vxMysteryOverview = () => import(/* webpackChunkName: "mystery-campaign" */ '../../../marketingpages/components/vx-mystery-overview/vx-mystery-overview.vue');
const vxMysteryOptIn = () => import(/* webpackChunkName: "mystery-campaign" */ '../../../marketingpages/components/vx-mystery-offer-opt-in/vx-mystery-offer-opt-in.vue');
const vxMysteryOptInThankYou = () => import(/* webpackChunkName: "mystery-campaign" */ '../../../marketingpages/components/vx-mystery-opt-in-thank-you/vx-mystery-opt-in-thank-you.vue');
const vxMysteryReferralThankYou = () => import(/* webpackChunkName: "mystery-campaign" */ '../../../marketingpages/components/vx-mystery-referral-thank-you/vx-mystery-referral-thank-you.vue');
const brawnyGTA = () => import(/* webpackChunkName: "gta" */ '../../../brawny/components/brawny-gta/brawny-gta.vue');
const vxCampaignOurBelief = () => import(/* webpackChunkName: "gta" */ '../../campaigns/vx-campaign-our-belief/vx-campaign-our-belief.vue');
const vxCampaignProgram = () => import(/* webpackChunkName: "gta" */ '../../campaigns/vx-campaign-program/vx-campaign-program.vue');
const vxCampaignGiants = () => import(/* webpackChunkName: "gta" */ '../../campaigns/vx-campaign-giants/vx-campaign-giants.vue');
const vxCampaignBrawnyTakesAction = () => import(/* webpackChunkName: "gta" */ '../../campaigns/vx-campaign-brawny-takes-action/vx-campaign-brawny-takes-action.vue');
const vxCampaignConnect = () => import(/* webpackChunkName: "gta" */ '../../campaigns/vx-campaign-connect/vx-campaign-connect.vue');
const vxCampaignFixedMenu = () => import(/* webpackChunkName: "gta" */ '../../campaigns/vx-campaign-fixed-menu/vx-campaign-fixed-menu.vue');
const vxPageLinks = () => import(/* webpackChunkName: "pagelinks" */ '../../../marketingpages/components/vx-pagelinks/vx-pagelinks.vue');

// import externalComponent from '../../common/mixins/external-component-mixin';

// const brawnyGTA = () => externalComponent('https://d2o3o2wreiygww.cloudfront.net/y/stg/ext/shared/brawny-gta/brawny-gta.c2bb2dc67417c3eb3d29.umd.min.js');
// Child
// const myComponent = () => externalComponent('https://d2o3o2wreiygww.cloudfront.net/y/stg/ext/shared/MyComponent/MyComponent.8c20b2b8fbe3f022c58e.umd.min.js');
// const myComponent = () => externalComponent('https://d2o3o2wreiygww.cloudfront.net/y/stg/ext/shared/MyComponent/MyComponent.9c089f2fd67755a3ca8e.umd.min.js');

export default {
  name: 'vx-marketing-form',
  props: {
    content: {
      type: String,
    },
    component: {
      required: true,
      type: Object,
    },
    props: {
      default: () => ({}),
      type: Object,
    },
  },

  render: function(createElement) {
    const self = this;
    return (function(cEl, content) {
      const util = {
        populate: () => {
          let formObj = document
            .createRange()
            .createContextualFragment(decodeURIComponent(content));
          let ret = {};
          if (formObj.firstChild) {
            const kids = util.parseChildren(formObj);

            if (kids && kids.length > 0) {
              ret = cEl('div', kids);
            } else {
              ret = cEl('div', 'Parse children failed');
            }
          }
          return ret;
        },
        compile: (el) => {
          try {
            try {
              let templateText = el.innerHTML;
              const result = Vue.compile(
                templateText.replace(/\s{2,}/g, ''),
              );
              return result.render;
            } catch (error) {
              return error.message;
            }
          } catch (e) {
            console.log(e);
            return cEl('div', [`parse: ${e.message}`]);
          }
        },
        parse: (el) => {
          try {
            /* Moved the vx- tag processing above children so that Vue can parse the children instead */
            if (el.tagName === 'BRAWNY-GTA') {
              try {
                let props = {};
                props.campaignData = JSON.parse(el.innerText);
                return cEl(brawnyGTA, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            // Brawny GTA Components
            if (el.tagName === 'VX-CAMPAIGN-OUR-BELIEF') {
              try {
                let props = {};
                props.ourBelief = JSON.parse(el.getAttribute(':ourbelief'));
                return cEl(vxCampaignOurBelief, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            if (el.tagName === 'VX-CAMPAIGN-PROGRAM') {
              try {
                let props = {};
                props.theProgram = JSON.parse(el.getAttribute(':theProgram'));
                return cEl(vxCampaignProgram, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }

            if (el.tagName === 'VX-CAMPAIGN-GIANTS') {
              try {
                let props = {};
                props.slideData = JSON.parse(el.getAttribute(':slide-data'));
                return cEl(vxCampaignGiants, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            if (el.tagName === 'VX-CAMPAIGN-BRAWNY-TAKES-ACTION') {
              try {
                let props = {};
                props.slideDataBta = JSON.parse(el.getAttribute(':slide-data-bta'));
                return cEl(vxCampaignBrawnyTakesAction, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            if (el.tagName === 'VX-CAMPAIGN-CONNECT') {
              try {
                let props = {};
                return cEl(vxCampaignConnect, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            if (el.tagName === 'VX-CAMPAIGN-FIXED-MENU') {
              try {
                let props = {};
                props.menus = JSON.parse(el.getAttribute(':menus'));
                return cEl(vxCampaignFixedMenu, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            if (el.tagName === 'VX-MYSTERY-REFERRAL') {
              try {
                let props = {};
                return cEl(vxMysteryReferral, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            if (el.tagName === 'VX-MYSTERY-REFERRAL-THANK-YOU') {
              try {
                let props = {};
                return cEl(vxMysteryReferralThankYou, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            if (el.tagName === 'VX-MYSTERY-OVERVIEW') {
              try {
                let props = {};
                return cEl(vxMysteryOverview, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            if (el.tagName === 'VX-MYSTERY-OPT-IN') {
              try {
                let props = {};
                return cEl(vxMysteryOptIn, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }
            if (el.tagName === 'VX-MYSTERY-OPT-IN-THANK-YOU') {
              try {
                let props = {};
                return cEl(vxMysteryOptInThankYou, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }

            if (el.tagName === 'VX-PAGELINKS') {
              try {
                let props = {};
                return cEl(vxPageLinks, { props });
              } catch (e) {
                return cEl('div', [`parse: ${e.message}`]);
              }
            }

            const attrs = util.parseAttributes(el);

            /* Process children for other tag types */
            const children = util.parseChildren(el, cEl);

            let ref = null;

            return cEl(el.tagName, { ref, attrs }, children);
          } catch (e) {
            console.log(e);
            return cEl('div', [`parse: ${e.message}`]);
          }
        },
        parseChildren: (el) => {
          const ret = [];
          try {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < el.childNodes.length; i += 1) {
              if (el.childNodes[i].nodeName && el.childNodes[i].nodeName.toLowerCase() === 'table' && util.hasClass(el.childNodes[i], 'GPformDef')) {
                let temp = self.ParseTable(el.childNodes[i]);
                console.log('temp', temp);
                ret.push(util.parse(temp, cEl)); // Don't need anything else from the table...
              } else if (el.childNodes[i].nodeName === '#text') {
                ret.push(el.childNodes[i].nodeValue);
              } else {
                ret.push(util.parse(el.childNodes[i], cEl));
              }
            }
          } catch (e) {
            console.log(e);
            return cEl('div', [`parseChildren: ${e.message}`]);
          }

          return ret;
        },
        produceVnode: (_vm, part1, part2) => {
          try {
            // debugger;
            // const func = new Function(`${vm}`, 'part1', 'part2', 'const ret = _vm._v(part1 + _vm._s(_vm.part1)); console.log(ret); return _ret;');
            const funcBody = `_vm._v("${part1}" + _vm._s(_vm.${part2}));`; // console.log("${part2}", ret); return _ret;`;
            console.log('funcBody', funcBody);
            // eslint-disable-next-line no-new-func
            const func = new Function(funcBody);
            return func.apply(self); // , [self, subParts[0], subParts[1]]);
          } catch (e) {
            console.log(e);
            console.log('e', e.message);
            return cEl('div', [`parseChildren: ${e.message}`]);
          }
        },

        parseAttributes: (el) => {
          const ret = {};
          // eslint-disable-next-line no-plusplus
          if (el.attributes) {
            for (let i = 0; i < el.attributes.length; i += 1) {
              if (el.attributes[i].name === 'ref') {
                // skipt this attrib
              } else if (el.attributes[i].name[0] === ':' || el.attributes[i].name.substring(0, 6) === 'v-bind:') {
                // eslint-disable-next-line no-new-func
                ret[el.attributes[i].name.substring(1)] = eval(`self.${el.attributes[i].value}`);
              } else {
                ret[el.attributes[i].name] = el.attributes[i].value;
              }
            }
          }

          return ret;
        },

        hasClass(el, className) {
          let classes = el.className ? el.className.split(' ') : [];
          return classes.indexOf(className) > -1;
        },

      };

      return util.populate();
    })(createElement, this.content);
  },

  data() {
    return {
      globals,
    };
  },

};
