const hidePopup = () => {
  setTimeout(() => { this.popupWrap.style.display = 'none'; }, 500);
  this.popupWrap.style.transition = 'all ease .5s';
  this.popupBox.style.transform = 'translate(-50%, -50%) scale(0.5)';
  this.popupWrap.style.opacity = 0;
  const successfulSubmit = this.$el.querySelector('.ThanksView').style.display === 'block';
  const label = successfulSubmit ? 'ClosePopUp-Success' : 'ClosePopUp';

  this.analyticsService.trackMarketingFormPost({
    event: 'promotionClick',
    action: 'engagement',
    label,
    ecommerce: {
      promoClick: {
        promotions: [{
          creative: this.config.formBehavior,
          name: 'form|optin-popup',
          position: document.title,
          id: this.config.opt_Attribution_Version,
        }],
      },
    },
  });
};

const showPopup = () => {
  this.popupWrap.style.display = 'block';
  setTimeout(() => {
    this.popupWrap.style.opacity = 1;
    this.popupBox.style.transform = 'translate(-50%, -50%) scale(1)';
  }, 10);
  this.analyticsService.trackMarketingFormPost({
    event: 'promotionView',
    action: 'view',
    label: 'impression',
    ecommerce: {
      promoView: {
        promotions: [{
          creative: this.config.formBehavior,
          name: 'form|optin-popup',
          position: document.title,
          id: this.config.opt_Attribution_Version,
        }],
      },
    },
  });
  // Save data to sessionStorage
  sessionStorage.setItem('popup', 'true');
  this.eraseCookie(this.globals.POPUP_TIME_FLAG);
};

const popupsMixin = {
  data() {
    return {
    };
  },
  computed: {

  },
  mounted() {
  },
  methods: { hidePopup, showPopup },
};

export default popupsMixin;
