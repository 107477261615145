import { TrackJS } from 'trackjs';
import globals from '../globals';
import cookiesMixin from './cookies-mixin';
import { trackJsBrandNames } from './vx-enums';

/**
 * sendToTrackJS is a method to pass error info to trackjs.
 * @param {object} err
 * @param {object} vm
 * @param {object} info
 * @return {object} null
 */

/**
 * cookies mixin is a common mixin with
 *  methods to create,read and erase a cookie.
 */
const trackjsMixin = {
  data() {
    return {
      globals,
    };
  },
  mixins: [cookiesMixin],
  methods: {
    sendToTrackJS(err, vm, info) {
      TrackJS.install({ token: '11715ef1097e485eae2042210b949753', application: this.getApplicationName(this.globals.siteId) });
      // Log properties passed to the component if there are any
      // if (vm.$options.propsData) {
      //   console.log('Props passed to component', vm.$options.propsData);
      // }
      // Emit component name and also the lifecycle hook the error occurred in if present
      let infoMessage = `Error in component: <${vm.$options.name} />\n`;
      TrackJS.addMetadata('error in component:', vm.$options.name);

      if (info) {
        infoMessage += `Error occurred during lifecycle hook: ${info}\n`;
        TrackJS.addMetadata('lifecycle hook:', info);
      }
      // This puts the additional error information in the Telemetry Timeline
      console.log(infoMessage);

      if (vm.$options.name === 'vx-pdp-product-info') {
        TrackJS.addMetadata('product id', vm.$options.propsData.productId);
      }
      if (vm.$options.name === 'vx-add-payment-form') {
        TrackJS.addMetadata('savedFormData', vm.$options.propsData.savedFormData);
        TrackJS.addMetadata('isCheckout', vm.$options.propsData.isCheckout);
        TrackJS.addMetadata('isFirstCard', vm.$options.propsData.isFirstCard);
        TrackJS.addMetadata('shippingAdresssChecked', vm.$options.propsData.shippingAdresssChecked);
        TrackJS.addMetadata('paymentResponseObj', vm.paymentResponseObj);
        TrackJS.addMetadata('isCardNameInvalid', vm.isCardNameInvalid);
        TrackJS.addMetadata('isValidCardExpiry', vm.isValidCardExpiry);
      }

      TrackJS.addMetadata('uid', vm.globals.uid);
      TrackJS.addMetadata('customer id', vm.globals.userInfo.customerId);
      TrackJS.addMetadata('cart guid', this.readCookie('guid'));
      TrackJS.addMetadata('auth_bearer_token', this.readCookie('auth_bearer_token'));
      const acceleratorSecureGUID = this.readCookie('acceleratorSecureGUID');
      if (acceleratorSecureGUID) {
        TrackJS.addMetadata('acceleratorSecureGUID', acceleratorSecureGUID);
      }
      const pkId = document.cookie.split(';').filter((c) => c.trim().indexOf('_pk_id') === 0).map((c) => c.trim().split('='));
      if (pkId.length) {
        TrackJS.addMetadata(pkId[0][0], pkId[0][1]);
      }
      // send err, metadata etc to trackjs
      TrackJS.track(err);
      console.log(err);
    },

    getApplicationName(siteId) {
      if (siteId === 'angelsoft') {
        return trackJsBrandNames.angelsoft;
      } if (siteId === 'aria') {
        return trackJsBrandNames.aria;
      } if (siteId === 'brawny') {
        return trackJsBrandNames.brawny;
      } if (siteId === 'dixieretail') {
        return trackJsBrandNames.dixieretail;
      } if (siteId === 'estores') {
        return trackJsBrandNames.estores;
      } if (siteId === 'gppro') {
        return trackJsBrandNames.gppro;
      } if (siteId === 'innovia') {
        return trackJsBrandNames.innovia;
      } if (siteId === 'quiltedNorthern') {
        return trackJsBrandNames.quiltedNorthern;
      } if (siteId === 'sparkle') {
        return trackJsBrandNames.sparkle;
      } if (siteId === 'vanityfairnapkins') {
        return trackJsBrandNames.vanityfair;
      }
      return 'All';
    },
  },
};
export default trackjsMixin;
