/**
 * common-service is a service file with
 *  all the common services embedded.
 */
import RootService from './root-service';
import globals from '../globals';

class CommonService extends RootService {
  /**
   * application auth service is used to get the authentication response.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  applicationAuthService(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('auth');
    config.params = globals.getApplicationParams();
    // In case you want to change the headers.
    const headers = {
      Authorization: '',
    };
    config.headers = headers;
    this.post(config, successCallback, errorCallback);
  }

  /**
   * getTermsAndCondition is a service to retrive  terms and conditions.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  getTermsAndCondition(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('termsCondition');
    this.get(config, successCallback, errorCallback);
  }

  /**
   * getRegions service is used to retrive the regions list.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   * @param {sring} isoCode
   * @param {boolean} isContactUs
   */
  getRegions(requestConfig, successCallback, errorCallback, isoCode, isContactUs) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('regions')}/${isoCode}`;
    if (isContactUs) {
      config.params = {
        contactUs: true,
      };
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * getCountries is a service to retrive list of countries.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   * @param {boolean} isShippingAddress
   */
  getCountries(requestConfig, successCallback, errorCallback, isShippingAddress) {
    const config = requestConfig;
    if (isShippingAddress) {
      config.url = `${globals.getRestUrl('getShippingCountry')}`;
    } else {
      config.url = `${globals.getRestUrl('getCountry')}`;
    }
    this.get(config, successCallback, errorCallback);
  }

  /**
   * addressVerification service is used to
   * post the address verification details.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  addressVerification(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('addressVerification');
    this.post(config, successCallback, errorCallback);
  }

  /**
   * deleteAddress is a service used to delete the address.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   * @param {string} addressId
   */
  deleteAddress(requestConfig, successCallback, errorCallback, addressId) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('getAddress', 'user')}/${addressId}`;
    this.delete(config, successCallback, errorCallback);
  }

  /**
   * switchB2bUnitProfiles is used to
   * post the UnitUID to switch the profiles.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   * @param {string} unitUid
   */
  switchB2bUnitProfiles(requestConfig, successCallback, errorCallback, requestParams) {
    const config = requestConfig;
    config.url = `${globals.getRestUrl('switchProfiles', 'user')}${requestParams.unitUid}?cartId=${requestParams.cartId}`;
    if (requestParams.clearCart) {
      config.url = `${config.url}&clearCart=${requestParams.clearCart}`;
    }
    this.post(config, successCallback, errorCallback);
  }

  /**
   * Service to get the page url to be shown in the marketing modal
   */
  getMarketingModalPage(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.url = globals.getRestUrl('marketingModal');
    this.get(config, successCallback, errorCallback);
  }
}
export {
  CommonService as
  default,
};
