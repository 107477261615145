/* eslint-disable no-undef */
/* eslint-disable camelcase */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'promise-polyfill/src/polyfill';
import Vue from 'vue';
import commonModule from '../modules/commonstatic';
import globals from '../components/common/globals';
import messages from '../locale/angelsoft/messages';
import nfrModule from '../modules/nfr';

const scriptURL = document.currentScript.src;
const parts = scriptURL.split('/');
__webpack_public_path__ = `${parts.slice(0, parts.length - 3).join('/')}/`;

Vue.config.productionTip = false;

const vm = new Vue({
  el: '#marketingpages',
  data: {
    globals,
    cartExists: true,
    messages: messages['en-US'],
  },
  mixins: [
    commonModule,
    nfrModule,
  ],
  components: {},
  created() {
    window.cartExists = this.cartExists;
  },
  mounted() {
    this.checkForSEparam();
    this.relocateFooter();
  },
  methods: {
    checkForSEparam() {
      const isSmartEdit = window.location.href.indexOf('cmsTicketId') > -1;
      if (isSmartEdit) {
        const allResponsiveScript = document.createElement('script');
        const targetScriptTag = document.getElementsByTagName('HEAD');
        allResponsiveScript.setAttribute('type', 'text/javascript');
        allResponsiveScript.setAttribute('src', '/gp/wro/all_responsive.js');
        allResponsiveScript.onload = function() {
          const addonsResponsiveScript = document.createElement('script');
          addonsResponsiveScript.setAttribute('type', 'text/javascript');
          addonsResponsiveScript.setAttribute('src', '/gp/wro/addons_responsive.js');
          if (targetScriptTag && targetScriptTag.length > 0) { targetScriptTag[0].appendChild(addonsResponsiveScript); }
        };
        if (targetScriptTag && targetScriptTag.length > 0) { targetScriptTag[0].appendChild(allResponsiveScript); }
      }
    },

    relocateFooter() {
      const footer = document.querySelector('footer');
      const mainDiv = document.querySelector('#main');

      if (mainDiv) {
        mainDiv.parentElement.insertBefore(footer, mainDiv.lastElementChild.nextElementSibling);
        mainDiv.firstElementChild.style.minHeight = `calc(100vh - ${footer.offsetHeight}px)`;
      }
    },
  },
  watch: {
    cartExists() {
      window.cartExists = this.cartExists;
    },
  },
  updated() {
  },
});

window.vm = vm;
