/**
 * createCookie is a method to createCookie.
 * @param {string} name
 * @param {string} value
 * @param {number} days
 * @return {object} null
 */
const createCookie = (name, value, days) => {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires= ${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};

/**
 * readCoookie is a menthod to read the cookie.
 * @param {string} name
 * @return {object} null
 */
const readCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return undefined;
};

/**
 * eraseCookie is used to delete the cookie.
 * @param {string} name
 */
const eraseCookie = (name) => {
  createCookie(name, '', -1);
};

/**
 * cookies mixin is a common mixin with
 *  methods to create,read and erase a cookie.
 */
const cookiesMixin = {
  methods: {
    createCookie,
    readCookie,
    eraseCookie,
  },
};

export default cookiesMixin;
