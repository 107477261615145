import vxApplicationAuth from '../components/common/vx-application-auth/vx-application-auth.vue';
import vxMarketingForm from '../components/common/vx-marketing-form/vx-marketing-form.vue';
import vxMarketingFormNew from '../components/common/vx-marketing-form/vx-marketing-form-new.vue';
import vxMarketingFormSms from '../components/common/vx-marketing-form/vx-marketing-form-sms.vue';
import initMixin from '../components/common/mixins/init-mixin';
import vxFlyoutBanner from '../components/common/vx-flyout-banner/vx-flyout-banner.vue';

const commonModule = {
  components: {
    vxApplicationAuth,
    vxMarketingForm,
    vxMarketingFormNew,
    vxMarketingFormSms,
    vxFlyoutBanner,

    mixins: [
      initMixin,
    ],
  },
};

export default commonModule;
