export default {
  name: 'vx-500-page',
  components: {},
  props: {
    i18n: {
      type: Object,
    },
  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
};
