/**
 * root-service is a service file
 * for all the type of methods to make API calls.
 */
// More details regarding config object can be found on https://github.com/axios/axios
import axios from 'axios';
import globals from '../globals';
import { globalEventBus } from '../../../modules/event-bus';
import { httpStatusCodes } from '../mixins/vx-enums';

class RootService {
  constructor() {
    const headerData = globals.getHeaders() || {};
    const service = axios.create({
      headers: headerData,
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  /**
   * success handler.
   * @param {object} response
   */
  static handleSuccess(response) {
    return response;
  }

  /**
   * error handler.
   * @param {object} error
   */
  static handleError(error) {
    return error;
  }

  /**
   * generateRequest is method to make the api request.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  generateRequest(requestConfig, successCallback, errorCallback) {
    return this.service(requestConfig)
      .then(
        (response) => successCallback(response),
        (error) => {
          if (error.response && error.response.status === httpStatusCodes.unauthorized) {
            // 401
            // Check Authorization (Hybris loggedin session exist)
            if (globals.getIsLoggedIn()) {
              // Logged In User.
              // Navigate the user back to Login Page.
              globals.logout(httpStatusCodes.unauthorized);
            } else {
              // If the user is a Guest user and if he gets 401, simply reload the page.
              window.location.reload();
            }
          }
          if (!error.response || !error.response.data) {
            globalEventBus.$emit('service-error');
          }
          errorCallback(error);
        },
      )
      .catch((error) => {
        errorCallback(error);
      });
  }

  /**
 * delete method.
 * @param {object} requestConfig
 * @param {function} successCallback
 * @param {function} errorCallback
 */
  delete(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.method = 'delete';
    this.generateRequest(config, successCallback, errorCallback);
  }

  /**
   * get method.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  get(requestConfig, successCallback, errorCallback) {
    const reqConfURL = requestConfig.url;
    let requestPath = '';

    if (reqConfURL.indexOf('deliverycountries') !== -1 || reqConfURL.indexOf('shippingcountries') !== -1) {
      requestPath = 'countries';
    } else

    if (reqConfURL.indexOf('expireyearlist') !== -1) {
      requestPath = 'expireyearlist';
    } else if (reqConfURL.indexOf('regions/US') !== -1) {
      requestPath = 'regions/US';
    } else if (reqConfURL.indexOf('regions/CA') !== -1) {
      requestPath = 'regions/CA';
    }

    switch (requestPath) {
      case 'countries': {
        if (globals.isB2C()) {
          successCallback({
            status: 200,
            data: {
              countries: [{
                isocode: 'US',
                name: 'United States of America',
              }],
            },
          });
        } else if (globals.isB2B()) {
          successCallback({
            status: 200,
            data: {
              countries: [{
                isocode: 'CA',
                name: 'Canada',
              }, {
                isocode: 'US',
                name: 'United States of America',
              }],
            },
          });
        }
        break;
      }
      case 'expireyearlist': {
        const yearList = [];
        // generating list of 10 years from current year
        for (let count = 0; count <= 10; count += 1) {
          yearList.push(new Date().getFullYear() + count);
        }
        successCallback({
          status: 200,
          data: yearList,
        });
        break;
      }

      case 'regions/US': {
        successCallback({
          status: 200,
          data: {
            regions: [{
              countryIso: 'US',
              isocode: 'US-AL',
              isocodeShort: 'AL',
              name: 'Alabama',
            }, {
              countryIso: 'US',
              isocode: 'US-AK',
              isocodeShort: 'AK',
              name: 'Alaska',
            }, {
              countryIso: 'US',
              isocode: 'US-AZ',
              isocodeShort: 'AZ',
              name: 'Arizona',
            }, {
              countryIso: 'US',
              isocode: 'US-AR',
              isocodeShort: 'AR',
              name: 'Arkansas',
            }, {
              countryIso: 'US',
              isocode: 'US-CA',
              isocodeShort: 'CA',
              name: 'California',
            }, {
              countryIso: 'US',
              isocode: 'US-CO',
              isocodeShort: 'CO',
              name: 'Colorado',
            }, {
              countryIso: 'US',
              isocode: 'US-CT',
              isocodeShort: 'CT',
              name: 'Connecticut',
            }, {
              countryIso: 'US',
              isocode: 'US-DE',
              isocodeShort: 'DE',
              name: 'Delaware',
            }, {
              countryIso: 'US',
              isocode: 'US-DC',
              isocodeShort: 'DC',
              name: 'District of Columbia',
            }, {
              countryIso: 'US',
              isocode: 'US-FL',
              isocodeShort: 'FL',
              name: 'Florida',
            }, {
              countryIso: 'US',
              isocode: 'US-GA',
              isocodeShort: 'GA',
              name: 'Georgia',
            }, {
              countryIso: 'US',
              isocode: 'US-HI',
              isocodeShort: 'HI',
              name: 'Hawaii',
            }, {
              countryIso: 'US',
              isocode: 'US-ID',
              isocodeShort: 'ID',
              name: 'Idaho',
            }, {
              countryIso: 'US',
              isocode: 'US-IL',
              isocodeShort: 'IL',
              name: 'Illinois',
            }, {
              countryIso: 'US',
              isocode: 'US-IN',
              isocodeShort: 'IN',
              name: 'Indiana',
            }, {
              countryIso: 'US',
              isocode: 'US-IA',
              isocodeShort: 'IA',
              name: 'Iowa',
            }, {
              countryIso: 'US',
              isocode: 'US-KS',
              isocodeShort: 'KS',
              name: 'Kansas',
            }, {
              countryIso: 'US',
              isocode: 'US-KY',
              isocodeShort: 'KY',
              name: 'Kentucky',
            }, {
              countryIso: 'US',
              isocode: 'US-LA',
              isocodeShort: 'LA',
              name: 'Louisiana',
            }, {
              countryIso: 'US',
              isocode: 'US-ME',
              isocodeShort: 'ME',
              name: 'Maine',
            }, {
              countryIso: 'US',
              isocode: 'US-MD',
              isocodeShort: 'MD',
              name: 'Maryland',
            }, {
              countryIso: 'US',
              isocode: 'US-MA',
              isocodeShort: 'MA',
              name: 'Massachusetts',
            }, {
              countryIso: 'US',
              isocode: 'US-MI',
              isocodeShort: 'MI',
              name: 'Michigan',
            }, {
              countryIso: 'US',
              isocode: 'US-MN',
              isocodeShort: 'MN',
              name: 'Minnesota',
            }, {
              countryIso: 'US',
              isocode: 'US-MS',
              isocodeShort: 'MS',
              name: 'Mississippi',
            }, {
              countryIso: 'US',
              isocode: 'US-MO',
              isocodeShort: 'MO',
              name: 'Missouri',
            }, {
              countryIso: 'US',
              isocode: 'US-MT',
              isocodeShort: 'MT',
              name: 'Montana',
            }, {
              countryIso: 'US',
              isocode: 'US-NE',
              isocodeShort: 'NE',
              name: 'Nebraska',
            }, {
              countryIso: 'US',
              isocode: 'US-NV',
              isocodeShort: 'NV',
              name: 'Nevada',
            }, {
              countryIso: 'US',
              isocode: 'US-NH',
              isocodeShort: 'NH',
              name: 'New Hampshire',
            }, {
              countryIso: 'US',
              isocode: 'US-NJ',
              isocodeShort: 'NJ',
              name: 'New Jersey',
            }, {
              countryIso: 'US',
              isocode: 'US-NM',
              isocodeShort: 'NM',
              name: 'New Mexico',
            }, {
              countryIso: 'US',
              isocode: 'US-NY',
              isocodeShort: 'NY',
              name: 'New York',
            }, {
              countryIso: 'US',
              isocode: 'US-NC',
              isocodeShort: 'NC',
              name: 'North Carolina',
            }, {
              countryIso: 'US',
              isocode: 'US-ND',
              isocodeShort: 'ND',
              name: 'North Dakota',
            }, {
              countryIso: 'US',
              isocode: 'US-OH',
              isocodeShort: 'OH',
              name: 'Ohio',
            }, {
              countryIso: 'US',
              isocode: 'US-OK',
              isocodeShort: 'OK',
              name: 'Oklahoma',
            }, {
              countryIso: 'US',
              isocode: 'US-OR',
              isocodeShort: 'OR',
              name: 'Oregon',
            }, {
              countryIso: 'US',
              isocode: 'US-PA',
              isocodeShort: 'PA',
              name: 'Pennsylvania',
            }, {
              countryIso: 'US',
              isocode: 'US-RI',
              isocodeShort: 'RI',
              name: 'Rhode Island',
            }, {
              countryIso: 'US',
              isocode: 'US-SC',
              isocodeShort: 'SC',
              name: 'South Carolina',
            }, {
              countryIso: 'US',
              isocode: 'US-SD',
              isocodeShort: 'SD',
              name: 'South Dakota',
            }, {
              countryIso: 'US',
              isocode: 'US-TN',
              isocodeShort: 'TN',
              name: 'Tennessee',
            }, {
              countryIso: 'US',
              isocode: 'US-TX',
              isocodeShort: 'TX',
              name: 'Texas',
            }, {
              countryIso: 'US',
              isocode: 'US-UT',
              isocodeShort: 'UT',
              name: 'Utah',
            }, {
              countryIso: 'US',
              isocode: 'US-VT',
              isocodeShort: 'VT',
              name: 'Vermont',
            }, {
              countryIso: 'US',
              isocode: 'US-VA',
              isocodeShort: 'VA',
              name: 'Virginia',
            }, {
              countryIso: 'US',
              isocode: 'US-WA',
              isocodeShort: 'WA',
              name: 'Washington',
            }, {
              countryIso: 'US',
              isocode: 'US-WV',
              isocodeShort: 'WV',
              name: 'West Virginia',
            }, {
              countryIso: 'US',
              isocode: 'US-WI',
              isocodeShort: 'WI',
              name: 'Wisconsin',
            }, {
              countryIso: 'US',
              isocode: 'US-WY',
              isocodeShort: 'WY',
              name: 'Wyoming',
            }],
          },

        });
        break;
      }
      case 'regions/CA': {
        successCallback({
          status: 200,
          data: {
            regions: [{
              countryIso: 'CA',
              isocode: 'CA-AB',
              isocodeShort: 'AB',
              name: 'Alberta',
            }, {
              countryIso: 'CA',
              isocode: 'CA-BC',
              isocodeShort: 'BC',
              name: 'British Columbia',
            }, {
              countryIso: 'CA',
              isocode: 'CA-MB',
              isocodeShort: 'MB',
              name: 'Manitoba',
            }, {
              countryIso: 'CA',
              isocode: 'CA-NB',
              isocodeShort: 'NB',
              name: 'New Brunswick',
            }, {
              countryIso: 'CA',
              isocode: 'CA-NL',
              isocodeShort: 'NL',
              name: 'Newfoundland and Labrador',
            }, {
              countryIso: 'CA',
              isocode: 'CA-NT',
              isocodeShort: 'NT',
              name: 'Northern Territories',
            }, {
              countryIso: 'CA',
              isocode: 'CA-NS',
              isocodeShort: 'NS',
              name: 'Nova Scotia',
            }, {
              countryIso: 'CA',
              isocode: 'CA-NU',
              isocodeShort: 'NU',
              name: 'Nunavut',
            }, {
              countryIso: 'CA',
              isocode: 'CA-ON',
              isocodeShort: 'ON',
              name: 'Ontario',
            }, {
              countryIso: 'CA',
              isocode: 'CA-PE',
              isocodeShort: 'PE',
              name: 'Prince Edward Island',
            }, {
              countryIso: 'CA',
              isocode: 'CA-QC',
              isocodeShort: 'QC',
              name: 'Quebec',
            }, {
              countryIso: 'CA',
              isocode: 'CA-SK',
              isocodeShort: 'SK',
              name: 'Saskatchewan',
            }, {
              countryIso: 'CA',
              isocode: 'CA-YT',
              isocodeShort: 'YT',
              name: 'Yukon Territory',
            }],
          },

        });
        break;
      }

      default: {
        const config = requestConfig;
        config.method = 'get';
        this.generateRequest(config, successCallback, errorCallback);
        break;
      }
    }
  }

  /**
   * patch method.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  patch(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.method = 'patch';
    this.generateRequest(config, successCallback, errorCallback);
  }

  /**
   * post method.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  post(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.method = 'post';
    this.generateRequest(requestConfig, successCallback, errorCallback);
  }

  /**
   * put method.
   * @param {object} requestConfig
   * @param {function} successCallback
   * @param {function} errorCallback
   */
  put(requestConfig, successCallback, errorCallback) {
    const config = requestConfig;
    config.method = 'put';
    this.generateRequest(requestConfig, successCallback, errorCallback);
  }
}

export {
  RootService as default,
};
