/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-new */
const getCollapsed = (orientation, collapsed, expanded, offsetWidth, offsetHeight) => {
  let thisY;
  const thisX = orientation === 'vertical' ? collapsed.width / expanded.width : 1;
  const horizontalY = offsetWidth > 750 ? 0.28 : 0.25;
  thisY = orientation === 'vertical' ? 1 : horizontalY;

  if (orientation === 'vertical' && offsetHeight > 300) {
    thisY = (220 / expanded.height);
    this.stickySubmitRotated.style.width = '210px';
  }
  return {
    x: thisX,
    y: thisY,
  };
};

const initSticky = (self) => {
  class Menu {
    constructor() {
      this.stickyForm = self.$el.querySelector('.sticky-form');
      this.menu = self.$el.querySelector('#sticky-form-inner');
      this.menuContents = self.$el.querySelector('.row.opt-in.GPform');
      this.stickyCloseButton = self.$el.querySelector('#sticky-close-btn');
      this.orientation = self.$el.querySelector('.sticky-form').classList.contains('sticky-left') || document.querySelector('.sticky-form').classList.contains('sticky-right') ? 'vertical' : 'horizontal';
      this.emailCssClasses = self.$el.querySelector('#sticky-email').parentElement.className;
      this.emailZipCssClasses = self.$el.querySelector('#sticky-email-zip').className;
      this.zipCssClasses = self.$el.querySelector('#sticky-zip').parentElement.className;
      this.stickyCloseBtnClasses = self.$el.querySelector('#sticky-close-btn').parentElement.className;
      this.stickySubmitRotated = self.$el.querySelector('#sticky-submit-rotated');
      this.stickySubmit = self.$el.querySelector('#sticky-submit');
      this.stickyThanksView = self.$el.querySelector('.StickyThanksView');
      this.stickyErrorMessage = self.$el.querySelector('#sticky-error-message');
      this.stickyHeader = self.$el.querySelector('#sticky-header');
      this.stickyParagraph = self.$el.querySelector('#sticky-paragraph');
      this.stickyZip = self.$el.querySelector('#sticky-zip');
      this.stickyEmail = self.$el.querySelector('#sticky-email');
      this.stickyEmailZip = self.$el.querySelector('#sticky-email-zip');
      this.stickyCloseBtn = self.$el.querySelector('#sticky-close-btn');
      this.stickyErrorMessageZip = self.$el.querySelector('#sticky-error-message-zip');

      if (this.orientation === 'horizontal') {
        this.stickySubmitRotated.style.display = 'none';
        const gtaPage = window.location.pathname.substring(1);
        if (self.$el.querySelector('.sticky-form.navbar-fixed-top')) {
          const offsetHeight = this.stickyForm.offsetHeight + 80;
          document.querySelector('#main').style.paddingTop = `${offsetHeight}px`;
          const headerHeight = document.querySelector('.header-section')[0].offsetHeight;
          // Adding additional margin to display GTA navigation on GTA page
          if (gtaPage === 'giants-take-action') {
            this.stickyForm.style.setProperty('margin-top', `${headerHeight + 48}px`, 'important');
          } else {
            this.stickyForm.style.marginTop = `${headerHeight}px`;
          }
        }
      } else {
        this.stickySubmit = this.stickySubmitRotated;
        this.stickySubmit.value = this.stickySubmitRotated.value; // needed?
        this.stickySubmit.style.width = `${this.menu.offsetHeight - 20}px`;
      }
      if (self.$el.querySelector('.sticky-form.navbar-fixed-bottom')) {
        const newParent = document.querySelector('footer').parentElement;
        newParent.insertBefore(this.stickyForm, document.querySelector('footer'));
        self.$el = this.stickyForm; // needed?
      }
      this.stickyThanksView.style.height = `${this.menuContents.offsetHeight}px`;
      this.expanded = true;
      this.animate = false;
      this.collapsed;
      this.expand = this.expand.bind(this);
      this.collapse = this.collapse.bind(this);
      this.toggle = this.toggle.bind(this);

      this.calculateScales();
      this.createEaseAnimations();
      this.addEventListeners();

      this.collapse();
      this.activate();
    }

    activate() {
      this.menu.classList.add('menu--active');
      this.animate = true;

      self.analyticsService.trackMarketingFormPost({
        event: 'promotionView',
        action: 'view',
        label: 'impression',
        ecommerce: {
          promoView: {
            promotions: [{
              creative: self.config.formBehavior,
              name: 'form|optin-sticky',
              position: document.title,
              id: self.config.opt_Attribution_Version,
            }],
          },
        },
      });
    }

    collapse() {
      if (!this.expanded) {
        return;
      }
      this.expanded = false;
      const { x, y } = this.collapsed;
      const invX = 1 / x;
      const invY = 1 / y;

      this.menu.style.transform = `scale(${x}, ${y})`;
      this.menuContents.style.transform = `scale(${invX}, ${invY})`;
      document.getElementById('sticky-error-message').style.display = 'none';
      document.getElementById('sticky-error-message-zip').style.display = 'none';

      if (this.orientation === 'vertical') {
        this.menuContents.style.opacity = 0;
        const that = this;
        setTimeout(() => {
          that.menuContents.style.opacity = 1;
          this.stickyHeader.style.visibility = 'hidden';
          this.stickyParagraph.style.visibility = 'hidden';
          this.stickyZip.style.visibility = 'hidden';
          this.stickyEmail.style.visibility = 'hidden';
          this.stickySubmit.style.visibility = 'hidden';
          this.stickySubmit.style.display = 'block';
        }, 900);
      } else {
        if (self.$el.querySelector('#stickyForm .checkbox_section')) {
          self.$el.querySelector('#stickyForm .checkbox_section').style.display = 'none';
        }
        this.stickyEmail.parentElement.className = '';
        this.stickyZip.parentElement.className = '';
        this.stickyEmailZip.className = '';
        this.stickyCloseBtn.className = '';
        this.stickyEmail.style.display = 'none';
        this.stickyZip.style.display = 'none';
        this.stickyEmailZip.style.display = 'none';
        this.stickyCloseBtn.style.display = 'none';
        const menuTop = this.menu.getBoundingClientRect().top;
        const menuBottom = this.menu.getBoundingClientRect().bottom;
        const submitTop = this.stickySubmit.getBoundingClientRect().top;
        const submitBottom = this.stickySubmit.getBoundingClientRect().bottom;

        const submitCollapseOffset = ((menuTop + menuBottom) / 2) - ((submitTop + submitBottom) / 2);
        this.stickySubmit.style.position = 'relative';
        this.stickySubmit.style.top = `${submitCollapseOffset}px`;
      }

      if (!this.animate) {
        return;
      }

      this.applyAnimation({ expand: false });

      self.analyticsService.trackMarketingFormPost({
        event: 'promotionClick',
        action: 'engagement',
        label: 'sticky-collapse',
        ecommerce: {
          promoClick: {
            promotions: [{
              creative: self.config.formBehavior,
              name: 'form|optin-sticky',
              position: document.title,
              id: self.config.opt_Attribution_Version,
            }],
          },
        },
      });
    }

    expand() {
      if (this.expanded) {
        return;
      }
      this.stickyErrorMessage.style.display = 'block';
      this.stickyErrorMessage.innerHTML = '';
      this.stickyErrorMessageZip.style.display = 'block';
      this.stickyErrorMessageZip.innerHTML = '';
      this.stickyEmail.removeAttribute('data-error-msg');
      this.stickyZip.removeAttribute('data-error-msg');
      if (this.orientation === 'vertical') {
        this.stickyHeader.style.visibility = 'visible';
        this.stickyParagraph.style.visibility = 'visible';
        this.stickyZip.style.visibility = 'visible';
        this.stickyEmail.style.visibility = 'visible';
        this.stickySubmit.style.visibility = 'visible';
        this.stickySubmit.style.display = 'none';
        this.menuContents.classList.add('fade-out');
        const that = this;
        setTimeout(() => { that.menuContents.classList.remove('fade-out'); }, 1);
        setTimeout(() => { that.menuContents.classList.add('fade-in'); }, 50);
      } else {
        if (document.querySelector('#stickyForm .checkbox_section')) {
          document.querySelector('#stickyForm .checkbox_section').style.display = 'block';
        }
        this.stickyEmail.parentElement.className = this.emailCssClasses;
        this.stickyZip.parentElement.className = this.zipCssClasses;
        this.stickyEmailZip.className = this.emailZipCssClasses;
        this.stickyCloseBtn.className = this.stickyCloseBtnClasses;
        this.stickyEmail.style.display = 'block';
        this.stickyZip.style.display = 'block';
        this.stickyEmailZip.style.display = 'block';
        this.stickyCloseBtn.style.display = 'block';
        this.stickySubmit.style.position = 'initial';
        this.stickyCloseBtn.focus();
      }

      this.expanded = true;

      this.menu.style.transform = 'scale(1, 1)';
      this.menuContents.style.transform = 'scale(1, 1)';

      if (!this.animate) {
        return;
      }

      this.applyAnimation({ expand: true });
      this.animate = true;

      self.analyticsService.trackMarketingFormPost({
        event: 'promotionClick',
        action: 'engagement',
        label: 'sticky-expand',
        ecommerce: {
          promoClick: {
            promotions: [{
              creative: self.config.formBehavior,
              name: 'form|optin-sticky',
              position: document.title,
              id: self.config.opt_Attribution_Version,
            }],
          },
        },
      });
    }

    toggle() {
      if (this.expanded) {
        this.stickySubmit.addEventListener('click', this.toggle);
        this.collapse();
        return;
      }
      this.stickySubmit.removeEventListener('click', this.toggle);
      this.expand();
    }

    addEventListeners() {
      this.stickyCloseButton.addEventListener('click', this.toggle);
      this.stickySubmit.addEventListener('click', this.toggle);
      const formMenu = this.menu;
      this.menu.querySelector('#sticky-close-success-btn').addEventListener('click', () => { formMenu.style.display = 'none'; });
    }

    applyAnimation({ expand } = opts) {
      this.menu.classList.remove('menu--expanded');
      this.menu.classList.remove('menu--collapsed');
      this.menuContents.classList.remove('menu__contents--expanded');
      this.menuContents.classList.remove('menu__contents--collapsed');

      // Force a recalc styles here so the classes take hold.
      window.getComputedStyle(this.menu).transform;

      if (expand) {
        this.menu.classList.add('menu--expanded');
        this.menuContents.classList.add('menu__contents--expanded');
        return;
      }

      this.menu.classList.add('menu--collapsed');
      this.menuContents.classList.add('menu__contents--collapsed');
    }

    calculateScales() {
      const collapsed = this.stickySubmit.getBoundingClientRect();
      collapsed.height += 30;
      collapsed.width += 10;

      const expanded = this.menu.getBoundingClientRect();

      this.collapsed = self.getCollapsed(this.orientation, collapsed, expanded, this.menuContents.offsetWidth, this.menuContents.offsetHeight);
    }

    createEaseAnimations() {
      let menuEase = document.querySelector('.menu-ease');
      if (menuEase) {
        return menuEase;
      }

      menuEase = document.createElement('style');
      menuEase.classList.add('menu-ease');

      const menuExpandAnimation = [];
      const menuExpandContentsAnimation = [];
      const menuCollapseAnimation = [];
      const menuCollapseContentsAnimation = [];
      for (let i = 0; i <= 100; i += 1) {
        const step = this.ease(i / 100);

        // Expand animation.
        this.append({
          i,
          step,
          startX: this.collapsed.x,
          startY: this.collapsed.y,
          endX: 1,
          endY: 1,
          outerAnimation: menuExpandAnimation,
          innerAnimation: menuExpandContentsAnimation,
        });

        // Collapse animation.
        this.append({
          i,
          step,
          startX: 1,
          startY: 1,
          endX: this.collapsed.x,
          endY: this.collapsed.y,
          outerAnimation: menuCollapseAnimation,
          innerAnimation: menuCollapseContentsAnimation,
        });
      }

      menuEase.textContent = `
      @keyframes menuExpandAnimation {
        ${menuExpandAnimation.join('')}
      }

      @keyframes menuExpandContentsAnimation {
        ${menuExpandContentsAnimation.join('')}
      }

      @keyframes menuCollapseAnimation {
        ${menuCollapseAnimation.join('')}
      }

      @keyframes menuCollapseContentsAnimation {
        ${menuCollapseContentsAnimation.join('')}
      }`;

      document.head.appendChild(menuEase);
      return menuEase;
    }

    append({
      i,
      step,
      startX,
      startY,
      endX,
      endY,
      outerAnimation,
      innerAnimation,
    } = opts) {
      const xScale = startX + (endX - startX) * step;
      const yScale = startY + (endY - startY) * step;

      const invScaleX = 1 / xScale;
      const invScaleY = 1 / yScale;

      outerAnimation.push(`
      ${i}% {
        transform: scale(${xScale}, ${yScale});
      }`);

      innerAnimation.push(`
      ${i}% {
        transform: scale(${invScaleX}, ${invScaleY});
      }`);
    }

    clamp(value, min, max) {
      return Math.max(min, Math.min(max, value));
    }

    ease(v, pow = 4) {
      v = this.clamp(v, 0, 1);

      return 1 - (1 - v ** pow);
    }
  }

  new Menu();
};

const stickyMixin = {
  data() {
    return {
    };
  },
  computed: {

  },
  mounted() {
  },
  methods: { getCollapsed, initSticky },
};

export default stickyMixin;
