const endpointUrls = {
  empty: '',
  auth: '/authorizationserver/oauth/token',
  termsCondition: '/termsAndConditions',
  register: '/users',
  getSignature: '/paymentsignature',
  getCountry: '/deliverycountries',
  getShippingCountry: '/shippingcountries',
  getStates: '/regions',
  getExpiryYears: '/expireyearlist',
  forgotPassword: '/forgottenpasswordtokens',
  updatePassword: '/forgottenpasswordtokens/updatepassword',
  kochAuthToken: '/kochauthtoken',
  surveyDetails: '/survey',
  searchUrl: '/products',
  compareUrl: '/products/compare',
  getProductsUrl: '/products',
  quickOrderSearchUrl: '/products/search',
  cmirProductMapUrl: '/products/quickorder',
  refillsUrl: '/multiEntries',
  allCategoryUrl: '/catalogs',
  autoComplete: '/products/search/autocomplete/SearchBox',
  wishlist: '/wishlist/all',
  saveMultipleLists: '/wishlist/addmultiplelists',
  notifyMe: '/stocknotifications',
  shareProduct: '/products/shareProduct',
  shareResource: '/products/shareProductResource',
  categories:
    'https://productlocator.iriworldwide.com/productlocator/products?client_id=25&brand_id=GAPA&prod_lvl=group&output=json',
  products:
    'https://productlocator.iriworldwide.com/productlocator/products?client_id=25&brand_id=GAPA&output=json&group_id=',
  stores:
    'https://productlocator.iriworldwide.com/productlocator/servlet/ProductLocatorEngine?clientid=25&productfamilyid=GAPA&producttype=upc&outputtype=json&storesperpage=999',
  getProducts: '/stores/products',
  getAllWishLists: '/wishlist/all',
  createList: '/wishlist',
  createSharedList: '/wishlist/createSharedList',
  saveListQuickOrder: '/wishlist/additems',
  addCustomAttributes: '/wishlist/addCustomAttr',
  saveCustomAttributes: '/wishlist/saveCustomAttribute',
  downloadExcel: '/wishlist/getProducts',
  pdpProductsUrl: '/products',
  createCart: '/carts',
  placeOrder: '/orders',
  businessUnits: '/units',
  addUsers: '/units/addUsers',
  getB2bUsers: '/units/b2bUsers',
  fetchAddress: '/users/current/addresses',
  saveCardDetails: '/users/current/paymentdetails/add',
  editCardDetails: '/users/current/paymentdetails',
  fetchSavedCards: '/users/current/paymentdetails',
  fetchSavedCardDetails: '/users/current/paymentdetails',
  saveCardGuest: '/paymentdetails',
  deleteList: '/wishlist/removeSelectedLists',
  updateQuantity: '/wishlist/updateQuantity',
  addListToCart: '/shoppingList',
  listLandingView: '/my-account/lists',
  listDetailView: '/my-account/listdetails',
  listPdpFormatDataUrl: '/products/productCodes/',
  updateWishlistName: '/wishlist/updateWishlistName',
  getsharedlist: '/wishlist/getsharedlist',
  deleteListEntry: '/wishlist/removeentry',
  removeCategory: '/wishlist/removecategory',
  removeUser: '/units/removeUsers',
  switchProfiles: '/units/updateProfile/',
  getSurveyData: '/survey/getsurvey',
  savePdfDetails: '/pdfDownloadUserDetails',
  bulkAddToCart: '/multipleproducts',
  getSupportTicketsB2C: '/ticket/getTicketDetails',
  getSupportTicketsB2B: '/ticket',
  updatePersonalDetails: '/updatepersonaldetails',
  getB2bPermissions: '/units/permissions/permissionsforunit',
  getUsers: '/units/users',
  userDetails: '/units/userdetails',
  addUser: '/units/userdetails/manageuser',
  getB2bParentUnits: '/units/addUsers',
  disableUser: '/units/disableUser',
  enableUser: '/units/enableUser',
  removePermission: '/units/permissions/removePermissionFromUserGroup',
  removeUsers: '/units/removeusergroup',
  getB2bUserGroups: '/units/usergroupsforunit',
  removeUnitFromUser: '/units/removeUnits',
  removePermissionFromUser: '/units/permissions/removePermissionToUser',
  removeUserGroupFromUser: '/units/removeUserGroupToUser',
  inviteUser: '/units/userdetails/inviteuser',
  regions: '/regions',
  resetPassword: '/reset',
  // Cart related Endpoints (has user and cart)
  validateCart: '/validateCart',
  suggestions: '/suggestions',
  vouchers: '/vouchers',
  deleteCartEntry: '/entries',
  editCartEntry: '/entries',
  shareCart: '/sharecart',
  subscribeProductToCart: '/addSubsCartEntries',
  getSubscriptionFrequency: '/getSubscriptionFrequency',
  subscriptionCart: '/subscription',
  getSubscriptionDetails: '/subscription/getsubscriptions',
  cancelSubscription: '/subscription',
  addProductToCart: '/entries',
  addMultipleProductsToCart: '/multipleentries',
  addAddress: '/addresses/delivery',
  productSolution: '/products/category',
  permissions: '/units/permissions',
  getPermissions: '/units/permissions/getPermissions',
  permissionDetails: '/permissions/details',
  disablePermissions: '/units/permissions/disablePermissions',
  enablePermissions: '/units/permissions/enablePermissions',
  editPermissions: '/units/permissions/editPermissions',
  addPermissions: '/units/permissions/addPermissions',
  getBusinessUnits: '/units/nodes',
  getAddress: '/addresses',
  checkoutGetAddress: '/getActiveAddresses',
  updateAddress: '/addresses/update',
  addressVerification: '/verifyaddress',
  getUserGroups: '/usergroups',
  getUserGroupDetails: '/usergroups/details',
  guestemail: '/email',
  shippingRestriction: '/shippingRestriction',
  getMultipleShippingMethod: '/multideliverymodes',
  getSingleShippingMethod: '/deliverymodes',
  scheduleInstallation: '/scheduleinstallation',
  setMultipleShippingMethod: '/splitentries/deliverymode',
  setSingleShippingMethod: '/deliverymode',
  setGiftWrap: '/giftwrap',
  paymentDetails: '/paymentdetails',
  paypalPaymentDetails: '/paypalpaymentdetails',
  paypalSavePayment: '/savePayPalPayerInfo',
  applepaysessiondetails: '/applepaysessiondetails',
  saveApplePayPaymentInfo: '/saveApplePayPaymentInfo',
  getGooglePayRequestParams: '/getGooglePayRequestParams',
  addGooglePayPaymentInfo: '/addGooglePayPaymentInfo',
  saveLeaseAgrement: '/saveleaseagreement',
  manageusergroup: '/manageusergroup',
  disableUserGroups: '/disableusergroup',
  removeUserGroup: '/removeusergroup',
  updateProfilePassword: '/password',
  marketingPreferences: '/marketingPreferences',
  updateMarketingPreferences: '/upDatePreferences',
  uploadTaxExemption: '/taxExemption/submit',
  getTaxExemption: '/taxExemption/status',
  addPermissionsToUserGroup: '/units/addPermissionsToUserGroup',
  addUsersToUserGroup: '/units/addUsersToUserGroup',
  addPermissionsToUser: '/units/addPermissionsToUsers',
  addUserGroupsToUser: '/units/addUserGroupsToUser',
  addUnitsToUser: '/units/addUnits',
  socialConnect: '/connect',
  socialDisconnect: '/disconnect',
  getOrder: '/orders',
  shareQuickList: '/products/quickorder/share',
  quickOrderSuggestions: '/products/quickorder/suggestions',
  // GP Xpress cart endpoints
  gpxpressCheckout: '/ordersamplecart',
  findDistributor: '/find-a-distributor',
  // manage Transaction related urls
  cancelOrder: '/orders',
  getB2COrderHistory: '/orders',
  getB2BOrderHistory: '/orders',
  getOrderDetails: '/orders',
  getOrderDetailsBuyer: '/orders/b2b',
  leaseAgreement: '/leaseagreement',
  getLease: '/getleaseagreement/',
  getB2BOrderApprovals: '/b2borders',
  orderStatus: '/b2border',
  // mardigras coupon endpoints
  checkIncompatibleProducts: '/checkIncompatibleProducts',
  distributors: '/stores',
  locateStore: '/find/locatestore',
  createContactUsTicket: '/ticket/createticket',
  submitContactUsAttachments: '/ticket/saveTicketAttachment',
  crossReferenceSearch: '/products/competitorCategory/',
  crossReferenceSearchByText:
    '/products/crossReferenceSearch/?query={query}&site={site}',
  getTopicOfInquiry: '/ticket/topicOfInquiry',
  getDispenserTicketParams: '/ticket/getdispenserticketparams',
  submitDispenserTicket: '/ticket/createdispenserticket',
  marketingModal: '/marketingModel',
  getIndustriesList: '/stores/industry',
  proceed: '/proceed',
};

export default endpointUrls;
