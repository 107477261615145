import globals from '../globals';

import { globalEventBus } from '../../../modules/event-bus';
import { flyoutStatus } from '../mixins/vx-enums';

export default {
  name: 'vx-flyout-banner',
  components: {},
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {
      flyoutObject: {
        status: 'error',
        message: 'Some error occurred, please try again later',
        autoDismiss: false,
        timer: 5000,
        storeAndShowOnce: false,
      },
      isPersisted: false,
      visible: false,
      globals,
      isCheckout: false,
      flyoutStatus,
      i18n: this.$root.messages.common.commonTitles,
    };
  },
  computed: {
  },
  created() {
    globalEventBus.$on('service-error', () => {
      this.flyoutObject.autoDismiss = true;
      this.showFlyout(this.flyoutObject);
    });
    globalEventBus.$on('is-checkout', (val) => {
      this.isCheckout = val;
    });
  },
  async mounted() {
    // On Mounted check if the previos page has some Msg
    this.checkIsPersisted();
    this.showStorageMsg();
  },
  methods: {
    /**
     * Checks whether flyout message has been set in the localstorage
     */
    checkIsPersisted() {
      if (this.globals.getStorage('flyoutMsg')) {
        this.isPersisted = true;
      }
    },
    /**
     * Shows stored message in the next loaded screen
     */
    showStorageMsg() {
      if (this.isPersisted) {
        const flyoutObject = JSON.parse(this.globals.getStorage('flyoutMsg'));
        this.showFlyout(flyoutObject);
      }
    },
    /**
     * Deletes the message store in the localstorage
     */
    deleteStorageMsg() {
      this.globals.deleteStorage('flyoutMsg');
    },
    /**
     * Shows the flyout in the current page
     * @param {object} flyoutObject
     */
    showFlyout(flyoutObject) {
      this.flyoutObject = flyoutObject;
      globalEventBus.$emit('announce', this.flyoutObject.message);
      this.visible = true;
      if (flyoutObject.autoDismiss) {
        (function(self) {
          setTimeout(() => self.dismissFlyout(), flyoutObject.timer || 3000);
        })(this);
      }
      if (flyoutObject.storeAndShowOnce) {
        this.deleteStorageMsg();
      }
    },
    /**
     * Shows msg on the next navigation page
     * @param {object} flyoutObject
     */
    showFlyoutNextPage(flyoutObject) {
      this.globals.setStorage('flyoutMsg', JSON.stringify(flyoutObject));
    },
    /**
     * Hides the FLyout banner
     * @param {object} event
     */
    dismissFlyout() {
      this.visible = false;
      if (this.isPersisted) this.deleteStorageMsg();
    },
  },
};
