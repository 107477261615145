/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable one-var */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable default-case */
/* eslint-disable object-shorthand */
import globals from '../globals';
import vxSpinner from '../vx-spinner/vx-spinner.vue';
import AnalyticsService from '../services/analytics-service';
import cookiesMixin from '../mixins/cookies-mixin';
import formattingMixin from '../mixins/formatting-mixin';
import stickyMixin from '../mixins/sticky-mixin';
import popupsMixin from '../mixins/popups-mixin';

export default {
  name: 'vx-marketing-form-sms-render',
  components: {
    vxSpinner,
  },
  props: {
    content: {
      type: String,
    },
    component: {
      required: true,
      type: Object,
    },
    props: {
      default: () => ({}),
      type: Object,
    },
  },
  mixins: [cookiesMixin, formattingMixin, stickyMixin, popupsMixin],
  created: function() {
    const util = {
      parseQueryString: () => {
        if (window.location.search.length > 0) {
          const q = window.location.search.substring(1).split('&');
          if (q) {
            for (let i = 0; i < q.length; i += 1) {
              let P = q[i].split('='),
                key = decodeURIComponent(P[0]),
                value = decodeURIComponent(P[1]);
              this.queryString[key.toLowerCase()] = value;
            }
          }
        }
      },
      applyQueryStringToConfig: () => {
        for (const prop in this.config) {
          if (this.queryString[prop.toLowerCase()]) {
            this.config[prop] = this.queryString[prop.toLowerCase()];
          }
        }
      },
    };
    util.parseQueryString();
    util.applyQueryStringToConfig();
  },

  async mounted() {
    this.checkForQueryString();
    this.parseAttributes();
    let siteName = this.globals.siteId === 'vanityfair' ? 'vanityfairnapkins' : this.globals.siteId;
    if (!siteName) { siteName = this.globals.siteId ? this.globals.siteId : 'marketingpages'; }
    if (this.config.submitUrl.length === 0) {
      switch (this.config.formBehavior.toLowerCase()) {
        case 'optin':
        case 'optin-sticky':
        case 'optin-popup':
          this.config.submitUrl = `/gpcommercewebservices/v2/${siteName}/utilityformsv3/explicitoptin`;
          break;
        case 'optout':
          this.config.submitUrl = `/gpcommercewebservices/v2/${siteName}/utilityformsv3/explicitoptout`;
          break;
        case 'saveformdata':
          this.config.submitUrl = `/gpcommercewebservices/v2/${siteName}/utilityformsv3`;
          break;
      }
    }
    if (this.config.getoptinstatusUrl.length === 0) {
      this.config.getoptinstatusUrl = `/gpcommercewebservices/v2/${siteName}//utilityformsv3/getoptstatus`;
    }
    if (this.config.opt_Attribution_Version.length === 0) {
      this.config.opt_Attribution_Version = this.config.intSource;
    }

    if (this.$el && this.$el.querySelector) {
      try {
        this.submitButton = this.$el.querySelector('.GPformButtonSubmit') || this.$el.querySelector('.optSubmit');
        this.emailField = this.$el.querySelector('input[type="email" i]');
        this.zipField = this.$el.querySelector('input[name="zip" i]');
        this.animatedCheckboxes = this.$el.querySelectorAll('label.radio-container input[data-animatedcheckbox');
        this.gpFormErrorMsgEmail = this.$el.querySelector('.GPformErrorMsgEmail');
        this.gpFormErrorMsgZip = this.$el.querySelector('.GPformErrorMsgZip');
        this.submitButton ? this.submitButton.addEventListener('click', this.GPsubmitForm) : null;
        this.emailField ? this.emailField.addEventListener('click', this.emailInputClick) : null;
        this.zipField ? this.zipField.addEventListener('click', this.zipInputClick) : null;
        this.animatedCheckboxes ? this.addCheckmark(this.animatedCheckboxes) : null;
        const validationInputs = this.$el.querySelectorAll('input[validation]');
        Array.from(validationInputs, (e) => e.addEventListener('keyup', this.GPFieldValidate));
        if (this.queryString.cpuserid && document.cookie.indexOf('cpUserId') === -1) {
          let CookieDate = new Date();
          CookieDate.setFullYear(CookieDate.getFullYear() + 10);
          document.cookie = `cpUserId=${this.queryString.cpuserid};expires=${CookieDate.toUTCString()}; path=/`;
        }

        if (this.config.formBehavior === 'optin-popup' && sessionStorage.getItem('popup') === null && document.cookie.indexOf('cpUserId') === -1) {
          // sessionStorage.setItem('popup', 'true');
          // Set a cookie with current date and time
          if (this.readCookie(this.globals.POPUP_TIME_FLAG) === null) {
            this.createCookie(this.globals.POPUP_TIME_FLAG, new Date());
          }
          const timeDiff = new Date() - new Date(this.readCookie(this.globals.POPUP_TIME_FLAG));

          if (!this.isFilteredPage('popup')) {
            this.popupCloseButton = this.$el.parentElement.querySelector('.popup-close');
            this.popupCloseButton ? this.popupCloseButton.addEventListener('click', this.hidePopup) : null;
            this.popupBox = this.$el.parentElement.querySelector('.popup-box');
            this.popupWrap = this.$el.parentElement.querySelector('.popup-wrap');

            if (timeDiff < this.config.popupdelay || sessionStorage.getItem('popup') === null) {
              const remainingTime = this.config.popupdelay - timeDiff;
              setTimeout(this.showPopup, remainingTime);
            }
          }
        } else if (this.config.formBehavior === 'optin') {
          this.intervalID = setInterval(this.checkFormPos, 5000);
        } else if (this.config.formBehavior === 'optin-sticky') {
          if (!this.isFilteredPage('sticky')) {
            this.initSticky(this);
          } else {
            this.$el.style.display = 'none';
          }
        }
      } catch (e) {
        console.log(e);
        console.log(`mounted: ${e.message}`);
      }
    }
  },
  data() {
    return {
      rendercount: 0,
      emailField: {},
      zipField: {},
      existingOpt: false,
      submitButton: {},
      queryString: {},
      gpFormErrorMsgEmail: {},
      gpFormErrorMsgZip: {},
      popupCloseButton: {},
      popupBox: {},
      popupWrap: {},
      analyticsService: new AnalyticsService(),
      globals,
      hasSpinner: false,
      pushDataEmail: true,
      pushDataZip: true,
      findingSubmitErrors: false,
      successfulSubmit: false,
      isZipvalid: false,
      isEmailvalid: false,
      intervalID: {},
      API_URL: '/gpcommercewebservices/v2/gppro/apiproxy',
      digitalCouponOfferChecked: false,
      config: {
        formName: '.GPform',
        formBehavior:
          '' /* 'optout', 'passthru', 'optin-popup', 'optin-sticky' */,
        popupdelay: '',
        triggerType: 'click',
        trigger: '.fpdp-btn',
        enableBriteVerify: true /* false */,
        submitUrl: '',
        targetType: 'url',
        thanksTarget: '/save/paper-towel-coupons/thanks',
        thanksTargetDigital: '',
        galleryTarget: '/save/paper-towel-coupons/gallery?cpuserid=',
        optoutTarget: '/opt-out/success',
        passthruTarget: '' /* Target URL for pass thru forms */,
        getoptinstatusUrl: '',
        preCheckOptStatus: true,
        intSource: '',
        opt_Attribution_Version: '',
        debug: false,
        initialized: false,
        authUrl: '/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials',
        urlPrefix: '',
        auth_token: '',
        directToGallery: false,
      },
    };
  },

  methods: {
    checkForQueryString() {
      const self = this;
      Object.keys(this.$el.querySelectorAll('input')).forEach((key) => {
        const inputName = self.$el.querySelectorAll('input')[key].name.toLowerCase();

        if (self.queryString[inputName]) {
          self.$el.querySelectorAll('input')[key].value = self.queryString[inputName];
        }
      });
    },
    parseAttributes() {
      const dataset = JSON.parse(JSON.stringify(this.$el.firstElementChild.dataset));
      const self = this;
      Object.keys(dataset).forEach((key) => {
        switch (`data-${key}`) {
          case 'data-submiturl':
            self.config.submitUrl = dataset[key];
            break;
          case 'data-thankstarget':
            self.config.thanksTarget = dataset[key];
            break;
          case 'data-thankstargetdigital':
            self.config.thanksTargetDigital = dataset[key];
            break;
          case 'data-targettype':
            self.config.targetType = dataset[key];
            break;
          case 'data-gallerytarget':
            self.config.galleryTarget = dataset[key];
            break;
          case 'data-getoptinstatusurl':
            self.config.getoptinstatusUrl = dataset[key];
            break;
          case 'data-intsource':
            self.config.intSource = dataset[key];
            break;
          case 'data-formbehavior':
            self.config.formBehavior = dataset[key];
            break;
          case 'data-popupdelay':
            self.config.popupdelay = dataset[key];
            break;
          case 'data-precheckoptstatus':
            self.config.preCheckOptStatus = dataset[key] === 'true';
            break;
          case 'data-opt_attribution_version':
            self.config.opt_Attribution_Version = dataset[key];
            break;
          case 'data-enablebriteverify':
            self.config.enableBriteVerify = dataset[key] === 'true';
            break;
          case 'data-directtogallery':
            self.config.directToGallery = dataset[key] === 'true';
            break;
        }
      });
    },
    GPisValidEmailAddress() {
      let email = this.emailField.value;
      if (email.length <= 4) return false;

      if (email[0] === ':') email = email.substr(1);

      const pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      return pattern.test(email);
    },
    DisableSubmit() {
      if (this.submitButton) {
        this.submitButton.setAttribute('disabled', true);
        this.submitButton.setAttribute('data-enabled-value', this.submitButton.getAttribute('value'));
        this.submitButton.setAttribute('value', 'WORKING...');
        if (this.$refs.spinner) {
          this.$refs.spinner.showSpinner();
        }
      }
    },
    EnableSubmit() {
      if (this.submitButton) {
        this.submitButton.removeAttribute('disabled');
        this.submitButton.setAttribute('value', this.submitButton.getAttribute('data-enabled-value'));
        this.submitButton.removeAttribute('data-enabled-value');
        if (this.$refs.spinner) {
          this.$refs.spinner.hideSpinner();
        }
      }
    },
    GPsubmitForm(e) {
      e.preventDefault();
      let brands = [this.globals.siteId];

      let errorCount = 0;
      const fields = this.$el.querySelectorAll('input, select, textarea');
      this.findingSubmitErrors = true;
      for (let i = 0; i < fields.length; i += 1) {
        const element = fields[i];
        errorCount += this.GPFieldValidate(element);

        let fieldName = element.getAttribute('name');
        fieldName = fieldName ? fieldName.toLowerCase() : '';
        if (fieldName === 'brands' && element.value) {
          brands = element.value.split(',');
        }
      }
      this.findingSubmitErrors = false;

      if (errorCount < 1) {
        this.DisableSubmit();
        // Define a method which will call BriteVerify from one the two steps below
        const callBriteVerify = () => {
          this.ValidateBriteVerify((success) => {
            if (success) {
              this.SendFormData();
              this.emailField.setAttribute('aria-invalid', false);
            } else {
              this.emailField.setAttribute('data-error-msg', 'The value of the Email field is not valid');
              this.emailField.setAttribute('aria-invalid', true);
              this.ShowErrors();
              this.EnableSubmit();
            }
          });
        };

        if (this.config.preCheckOptStatus) {
          this.GetUserOptInStatus((data) => {
            this.existingOpt = false;
            if (data.results.success) {
              if (data.results.opts.length > 0) {
                for (let i = 0; i < brands.length; i += 1) {
                  let brand = brands[i];
                  if (!this.existingOpt) {
                    for (let j = 0; j < data.results.opts.length; j += 1) {
                      this.existingOpt = brand.toLowerCase() === data.results.opts[j].brand.toLowerCase();
                    }
                  }
                }
              }
            }
            if (!this.existingOpt && this.config.enableBriteVerify) {
              callBriteVerify();
            } else {
              this.SendFormData();
            }
          });
        } else if (this.config.enableBriteVerify) {
          callBriteVerify();
        } else {
          this.SendFormData();
        }
      }
    },
    addClass(el, className) {
      let classes = el.className ? el.className.split(' ') : [];
      if (classes.indexOf(className) === -1) {
        classes.push(className);
        // eslint-disable-next-line no-param-reassign
        el.className = classes.join(' ');
      }
    },
    hasClass(el, className) {
      let classes = el.className ? el.className.split(' ') : [];
      return classes.indexOf(className) > -1;
    },
    removeClass(el, className) {
      let classes = el.className ? el.className.split(' ') : [];
      const ret = [];
      for (let i = 0; i < classes.length; i += 1) {
        let e = classes[i];
        if (e !== className) {
          ret.push(e);
        }
      }
      // eslint-disable-next-line no-param-reassign
      el.className = ret.join(' ');
    },
    GPFieldValidate(e) {
      const el = e.target ? e.target : e;
      let errorMessage = '';
      if (!(el && el.getAttribute('validation'))) { // && el.is(':visible')
        return 0;
      }
      let validation = el.getAttribute('validation');
      let dataFormat = el.getAttribute('data-format');
      validation = validation ? validation.toLowerCase() : '';

      this.removeClass(el, 'GPformError');
      el.setAttribute('data-error-msg', '');
      el.setAttribute('aria-invalid', '');

      if (validation.indexOf('required') > -1
        && (el.value === '')) { // || (el.is(':checkbox') && el.is(':checked')))) {
        errorMessage = `${el.getAttribute('name')} - Required field missing`;
        if (this.findingSubmitErrors) {
          this.analyticsService.trackMarketingFormPost({
            event: 'error',
            action: 'missing field',
            label: `${this.config.formBehavior} : ${errorMessage}`,
          });
        }
      }

      if (validation.indexOf('zip') > -1 && el.value !== '') {
        if (el.value.length < 5 || !/^(?!00000)(?!99999)\d{5}(?:[-\s]\d{4})?$/.test(el.value)) {
          // checks if zip is at least 5 digits, no
          errorMessage = 'Zip Code is not valid';
          this.isZipvalid = false;
          if (this.findingSubmitErrors) {
            this.analyticsService.trackMarketingFormPost({
              event: 'error',
              action: 'invalid zipcode',
              label: `${this.config.formBehavior} : ${errorMessage}`,
            });
          }
          this.zipFieldEdited = this.zipFieldEdited ? this.zipFieldEdited : !this.zipFieldEdited;
        } else {
          this.isZipvalid = true;
          el ? el.setAttribute('aria-invalid', false) : '';
          this.gpFormErrorMsgZip.innerHTML = '';
        }
      }
      if (el.value.length > 0) {
        this.addClass(el, 'hasInput');
      } else if (this.hasClass(el, 'hasInput') && el.value.length === 0) {
        this.removeClass(el, 'hasInput');
      }

      if (validation.indexOf('phone') > -1) {
        const phoneFormattingRequired = dataFormat.indexOf('phone') > -1;
        // charcount will be 10 when formatting is not needed and 15 otherwise
        let charCount = phoneFormattingRequired ? 12 : 10;
        el.setAttribute('maxlength', `${phoneFormattingRequired ? 15 : 10}`);
        el.value = el.value.replace(/[^+0-9.]/g, '');
        if (el.value.length && el.value.length !== charCount) {
          errorMessage = 'Phone is not valid';
          if (this.findingSubmitErrors) {
            this.analyticsService.trackMarketingFormPost({
              event: 'error',
              action: 'invalid phone',
              label: `${this.config.formBehavior} : ${errorMessage}`,
            });
          }
        }

        if (phoneFormattingRequired) {
          let phoneNumber = el.value;
          el.value = this.formatPhoneNumber(phoneNumber);
        }
      }
      if (validation.indexOf('email') > -1 && el.value !== '') {
        if (!this.GPisValidEmailAddress(el.value)) {
          errorMessage = 'Email isn\'t valid.';
          this.isEmailvalid = false;
          if (this.findingSubmitErrors) {
            this.analyticsService.trackMarketingFormPost({
              event: 'error',
              action: 'invalid email',
              label: `${this.config.formBehavior} : ${errorMessage}`,
            });
          }
        } else {
          this.isEmailvalid = true;
          el ? el.setAttribute('aria-invalid', false) : '';
          this.gpFormErrorMsgEmail.innerHTML = '';
        }
        this.emailFieldEdited = this.emailFieldEdited ? this.emailFieldEdited : !this.emailFieldEdited;
      }

      if (validation.indexOf('no-futuredate') > -1 && el.value !== '') {
        const providedDate = new Date(el.value);
        if (providedDate > new Date()) {
          errorMessage = `${el.name} - cannot be a future date`;
          if (this.findingSubmitErrors) {
            this.analyticsService.trackMarketingFormPost({
              event: 'error',
              action: 'invalid date',
              label: `${this.config.formBehavior} : ${errorMessage}`,
            });
          }
        }
      }

      if (errorMessage) {
        this.addClass(el, 'GPformError');
        el.setAttribute('data-error-msg', errorMessage);
        if (el.value === '') {
          el ? el.setAttribute('aria-invalid', false) : '';
        } else if ((el.value !== null || el.value !== '') && !this.isEmailvalid) {
          el ? el.setAttribute('aria-invalid', true) : '';
        }
        if (el.value === '') {
          el ? el.setAttribute('aria-invalid', false) : '';
        } else if ((el.value !== null || el.value !== '') && !this.isZipvalid) {
          el ? el.setAttribute('aria-invalid', true) : '';
        }
      }

      this.ShowErrors();

      if (errorMessage) {
        return 1;
      }
      return 0;
    },
    SendFormData() {
      import('axios').then((axios) => {
        const formData = {};
        formData.existing_opt = this.existingOpt;
        const fields = this.$el.querySelectorAll('input, select, textarea');
        const altFields = [];
        for (let i = 0; i < fields.length; i += 1) {
          let element = fields[i];
          let fieldName = element.getAttribute('name');
          let altFieldName = element.getAttribute('data-altFieldName');
          fieldName = fieldName ? fieldName.toLowerCase() : '';
          if (fieldName && element.value && element.type.toLowerCase() !== 'submit'
              && (element.type !== 'radio' || (element.type === 'radio' && element.checked)) && (element.type !== 'checkbox' || (element.type === 'checkbox' && element.checked))) {
            if (element.dataset.isdccheck === 'true') {
              this.digitalCouponOfferChecked = true;
            }
            if (altFieldName) {
              altFields.push({ fieldName: altFieldName, value: element.value });
            } else if (element.type === 'checkbox') {
              if (fieldName in formData) {
                if (Array.isArray(formData[fieldName])) {
                  formData[fieldName].push(element.value);
                } else {
                  formData[fieldName] = [formData[fieldName], element.value];
                }
              } else {
                formData[fieldName] = element.value;
              }
            } else {
              formData[fieldName] = element.value;
              if (fieldName === 'email') {
                const email = formData[fieldName];
                // The ":" prefix is a way to let the user bypass the BriteVerify call
                if (email[0] === ':') {
                  formData[fieldName] = email.substr(1);
                }
              }
            }
          }
        }
        if (altFields.length > 0) {
          for (let i = 0; i < altFields.length; i++) {
            formData[altFields[i].fieldName] = altFields[i].value;
          }
        }
        if (!formData.brands) {
          formData.brands = this.globals.siteId.toLowerCase();
        }
        if (!formData.referring_url) {
          formData.referring_url = window.location.href;
        }
        if (!formData.int_source && this.config.intSource) {
          formData.int_source = this.config.intSource;
        }
        const expFields = this.getExperimentData();
        if (expFields) {
          formData.experiment_id = expFields.experiment_id;
          formData.experiment_variant_id = expFields.experiment_variant_id;
        }

        if (this.config.formBehavior === 'saveformdata') {
          if (formData['opt-in'] === '1' || formData.smsaction === 'optin') {
            this.config.submitUrl = `${this.config.submitUrl}/explicitoptin`;
          } else {
            this.config.submitUrl = `${this.config.submitUrl}/saveformdata`;
          }
        }

        this.GetAuthToken((success) => {
          if (success) {
            // eslint-disable-next-line prefer-template
            axios.post(this.config.urlPrefix + this.config.submitUrl, this.ObjectToQueryString(formData), { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `bearer ${this.config.access_token}` } })
              .then(this.handleSendFormDataResponse)
              .catch(this.handleSendFormDataError);
          }
        });
      });
    },
    GetAuthToken(callback) {
      const self = this;
      if (this.config.access_token) {
        if (callback) {
          callback.call(self, true);
        }
      } else {
        // eslint-disable-next-line prefer-template
        import('axios').then((axios) => {
          axios.post(this.config.urlPrefix + this.config.authUrl)
            .then((response) => {
              if (response && response.data && response.data.access_token) {
                this.config.access_token = response.data.access_token;
                if (callback) {
                  callback.call(self, true);
                }
              } else {
                this.handleSendFormDataError.call('The server is unable to accept this form post at this time.');
              }
            })
            .catch(this.handleSendFormDataError);

          // https://localhost:9002/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials
        /*
          {
              "access_token": "e13b0ab7-b93a-4fa0-b38d-b1c05e2b04aa",
              "token_type": "bearer",
              "expires_in": 42074,
              "scope": "basic openid"
          }
        */
        });
      }
    },
    handleSendFormDataError(errorMessage) {
      let errorMessageInternal = 'There was a problem submitting the form. Please try again.';
      this.analyticsService.trackMarketingFormPost({
        event: 'error',
        action: 'view',
        label: `${this.config.formBehavior} : ${errorMessageInternal}`,
      });
      if (errorMessage) {
        errorMessageInternal = errorMessage;
      }

      this.EnableSubmit();
      if (errorMessageInternal) {
        this.emailField.setAttribute('data-error-msg', errorMessageInternal);
        this.emailField.setAttribute('aria-invalid', true);
        this.ShowErrors();
      }
    },
    handleSendFormDataResponse(response) {
      const data = response.data.results ? response.data : JSON.parse(response.data);
      if (!data.results.success) {
        if (data.results.error.length > 0) {
          this.handleSendFormDataError(data.results.error); // .join('</br>')
        } else {
          this.handleSendFormDataError();
        }
        return;
      }

      let cpUserId = '';
      if (data.results.cpuserid) {
        cpUserId = data.results.cpuserid;
        // set cookie to expire in 10 years (HL)
        let CookieDate = new Date();
        CookieDate.setFullYear(CookieDate.getFullYear() + 10);
        document.cookie = `cpUserId=${cpUserId};expires=${CookieDate.toUTCString()}; path=/`;
      }

      let analyticsCallbackHandled = false;
      const self = this;
      const analyticsCallback = function() {
        // gets called for each GTM tag
        if (analyticsCallbackHandled) {
          return;
        }
        analyticsCallbackHandled = true;

        self.EnableSubmit();
        if (self.config.targetType === 'url') {
          if (self.digitalCouponOfferChecked) {
            document.location = self.existingOpt ? (`${self.config.thanksTargetDigital}?existingOpt=true`) : self.config.thanksTargetDigital;
          } else {
            document.location = self.existingOpt || self.config.directToGallery ? (self.config.galleryTarget + cpUserId) : self.config.thanksTarget;
          }
        } else if (self.config.targetType === 'element') {
          if (self.$el.querySelector('.campaignOptForm')) {
            self.$el.querySelector('.campaignOptForm').style.margin = '5rem';
          }
          if (self.existingOpt) {
            document.location = self.config.galleryTarget + cpUserId;
          } else {
            self.$el.querySelector(self.config.thanksTarget).style.display = 'block';
            self.$el.querySelector('.FormView').style.display = 'none';
          }
        } else if (self.config.targetType === 'sticky') {
          if (self.existingOpt) {
            document.location = self.config.galleryTarget + cpUserId;
          } else {
            self.$el.querySelector(self.config.thanksTarget).style.display = 'block';
            self.$el.querySelector('.FormView').style.display = 'none';
          }
        }
      };
      switch (this.config.formBehavior) {
        case 'optin-popup': this.analyticsService.trackMarketingFormPost({
          event: 'promotionClick',
          action: 'submit',
          label: this.existingOpt ? 'existingopt' : 'newopt',
          ecommerce: {
            promoClick: {
              promotions: [{
                creative: this.config.formBehavior,
                name: 'form|optin-popup',
                position: document.title,
                id: this.config.opt_Attribution_Version,
              }],
            },
          },
          eventCallback: analyticsCallback,
        });
          break;
        case 'optin':
        case 'optin-sticky': {
          this.analyticsService.trackMarketingFormPost({
            event: 'promotionClick',
            action: 'submit',
            label: this.existingOpt ? 'existingopt' : 'newopt',
            ecommerce: {
              promoClick: {
                promotions: [{
                  creative: this.config.formBehavior,
                  name: 'form|optin',
                  position: document.title,
                  id: this.config.opt_Attribution_Version,
                }],
              },
            },
            eventCallback: analyticsCallback,
          });
          break;
        } case 'optout': {
          this.analyticsService.trackMarketingFormPost({
            eventCategory: 'optoutform',
            eventAction: 'submit',
            eventLabel: 'optout',
            event: 'fireTags',
            cpUserId: cpUserId,
            eventCallback: analyticsCallback,
          });
          break;
        } default: {
          this.analyticsService.trackMarketingFormPost({
            eventCategory: 'gpform',
            eventAction: 'submit',
            eventLabel: 'passthru',
            event: 'fireTags',
            cpUserId: cpUserId,
            eventCallback: analyticsCallback,
          });
          break;
        }
      }
    },
    ObjectToQueryString(object) {
      if (object) {
        return Object.keys(object).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`).join('&');
      }
      return '';
    },
    GetUserOptInStatus(callback) {
      const email = this.emailField.value;
      if (email.length > 0 && email[0] === ':') {
        this.email = email.substr(1);
      }
      this.GetAuthToken((success) => {
        if (success) {
          import('axios').then((axios) => {
          // eslint-disable-next-line prefer-template
            axios.post(this.config.urlPrefix + this.config.getoptinstatusUrl, this.ObjectToQueryString({
              email: email,
              brands: this.globals.siteId,
            }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `bearer ${this.config.access_token}` } })
              .then((response) => {
                if (callback) {
                  const resp = JSON.parse(response.data);
                  // eslint-disable-next-line eqeqeq
                  if (resp.results && resp.results.success) { resp.results.success = (resp.results.success === 'true'); }
                  callback.call(this, resp);
                }
              })
              .catch(() => {
                if (callback) {
                  callback.call(this, { results: { success: false } });
                }
              });
          });
        }
      });
    },
    ValidateBriteVerify(callback) {
      if (this.emailField.value[0] === ':') {
        callback.call(this, true);
        return;
      }
      const capitalize = (tempSiteID) => {
        if (typeof tempSiteID !== 'string') return '';
        return tempSiteID.charAt(0).toUpperCase() + tempSiteID.slice(1);
      };
      const newSiteID = capitalize(this.globals.siteId);
      const request = {
        remoteName: `${newSiteID}-BV`,
        remoteUrl: '/',
        remoteHeaders: ['Content-Type:application/json', 'Authorization:ApiKey: {$remote_access_token}'],
        postData: `{"email": "${this.emailField.value}"}`,
      };
      this.GetAuthToken((success) => {
        if (success) {
          const options = {
            headers: {
              Authorization: `bearer ${this.config.access_token}`,
              'Content-Type': 'application/json',
            },
          };

          import('axios').then((axios) => {
            axios.post(
              this.API_URL,
              request,
              options,
            ).then((data) => {
              const response = JSON.parse(JSON.parse(data.request.response));
              if (
                response
              && response.email.status !== 'invalid'
              && response.email.status !== 'unknown'
              && response.email.status !== 'accept_all'
              && response.email.disposable !== true
              && response.email.disposable !== 'true'
              ) {
                callback.call(this, true);
              } else {
                callback.call(this, false);
              }
            }).catch(() => {
              callback.call(this, true);
            });
          });
        }
      });
    },
    ShowErrors() {
      const out = [];
      const errors = this.$el.querySelectorAll('[data-error-msg]');
      for (let i = 0; i < errors.length; i += 1) {
        let el = errors[i];
        if (el.hasAttribute('data-error-msg')) {
          // try searching for inline error message div having the following data format
          const errorFor = this.$el.querySelector(`[data-error-for="${el.name}"]`);
          // check if the inline error div is available in form
          if (errorFor !== null) {
            //  this for setting inline error messages
            errorFor.innerHTML = el.getAttribute('data-error-msg');
          } else {
            // setting validation error messages at bottom of page if inline option is not available for the page
            out.push(el.getAttribute('data-error-msg'));
          }
        }
      }
      for (let i = 0; i < out.length; i++) {
        if (out[i].length) {
          out[i].includes('Email')
            ? this.gpFormErrorMsgEmail.innerHTML = out[i] : '';
          out[i].includes('zip') || out[i].includes('Zip')
            ? this.gpFormErrorMsgZip.innerHTML = out[i] : '';
        }
      }
    },

    isFilteredPage(formType) {
      const excludedPages = formType === 'sticky' ? [
        '/save',
        '/savings',
        '/thanks',
        '/gallery',
        'coupon',
        '/win-a-year-supply-sweeps',
      ] : [
        '/save',
        '/savings',
        '/thanks',
        '/thank-you',
        '/gallery',
        '/cart',
        '/checkout',
        '/covid',
        '/opt-out',
        '/giants-take-action',
        '/give-with-angel-soft',
        '/cooking-at-home',
        '/meet-the-chefs',
        '/cajun-fried-rice',
        '/chocolate-chip-cookies',
        '/coconut-curry-chicken',
        '/easy-orecchiette-pasta',
        '/pet',
        '/pet/3-layer-paper-towels',
        '/pet/cleaning-cloths',
        '/pet/wet-cleaning-wipes',
        '/brawny-whoops-pet',
        '/win-a-year-supply-sweeps',
      ];

      const includePages = [
        '/orderConfirmation',
      ];
      for (let i = 0; i < includePages.length; i += 1) {
        if (window.location.href.indexOf(includePages[i]) > 0) {
          return false;
        }
      }
      for (let i = 0; i < excludedPages.length; i += 1) {
        if (excludedPages[i][0] !== '/') {
          if (window.location.host.indexOf(excludedPages[i]) >= 0 && window.location.pathname === '/') {
            return true;
          }
        } else if (window.location.href.indexOf(excludedPages[i]) >= 0) {
          return true;
        }
      }

      return false;
    },

    radioSelection(e) {
      const el = e.target ? e.target : e;
      const sibEls = el.parentElement.childNodes;
      for (let i = 0; i < sibEls.length; i += 1) {
        if (sibEls[i].type === 'hidden') {
          sibEls[i].value = el.value;
          this.GPFieldValidate(sibEls[i]);
          break;
        }
      }
    },

    emailInputClick() {
      if (this.pushDataEmail) {
        this.analyticsService.trackMarketingFormPost({
          event: 'promotionClick',
          action: 'engagement',
          label: 'email',
          ecommerce: {
            promoClick: {
              promotions: [{
                creative: this.config.formBehavior,
                name: `form|${this.config.formBehavior}`,
                position: document.title,
                id: this.config.opt_Attribution_Version,
              }],
            },
          },
        });
      }
      this.pushDataEmail = false;
    },

    zipInputClick() {
      if (this.pushDataZip) {
        this.analyticsService.trackMarketingFormPost({
          event: 'promotionClick',
          action: 'engagement',
          label: 'zipcode',
          ecommerce: {
            promoClick: {
              promotions: [{
                creative: this.config.formBehavior,
                name: `form|${this.config.formBehavior}`,
                position: document.title,
                id: this.config.opt_Attribution_Version,
              }],
            },
          },
        });
      }
      this.pushDataZip = false;
    },
    checkFormPos() {
      let elementBoundingBox = this.$el.getBoundingClientRect();
      let elementTopY = elementBoundingBox.top;
      let elementBottomY = elementBoundingBox.top + elementBoundingBox.height;
      let inview = elementTopY >= 0 && elementBottomY <= Math.min(document.documentElement.clientHeight, window.innerHeight || 0);

      if (inview) {
        this.analyticsService.trackMarketingFormPost({
          event: 'promotionView',
          action: 'view',
          label: 'impression',
          ecommerce: {
            promoView: {
              promotions: [{
                creative: this.config.formBehavior,
                name: 'form|optin',
                position: document.title,
                id: this.config.opt_Attribution_Version,
              }],
            },
          },
        });
        clearInterval(this.intervalID);
      }
    },

    addCheckmark(checkboxInputs) {
      checkboxInputs.forEach((cbInput) => {
        const cbDiv = document.createElement('div');
        cbDiv.classList.add('checkmark', 'mr-xs-5');
        cbInput.parentNode.insertBefore(cbDiv, cbInput.nextSibling);
      });
    },

    getExperimentData() {
      const expFields = { experiment_id: 'no_value_set', experiment_variant_id: 'no_value_set' };
      let b = Array.from(Object.values(gaData));
      if (b !== undefined) {
        if (b.filter((o) => o.hasOwnProperty('experiments')).length > 0) {
          for (i = 0; i < b.length; i += 1) {
            let c = b[i];
            let d = Object.keys(c);
            if (d.includes('experiments')) {
              expFields.experiment_id = Object.keys(b[i].experiments);
              expFields.experiment_variant_id = Object.values(b[i].experiments);
            }
          }
        }
      }
      return expFields; // gaData or experiments data is missing
    },
  },
};
