const common = {
  headerSection: {
    iconSearchTitle: 'Search',
  },
  headerData: {
    headerOptions: {
      brandLogo: 'images/logo.svg',
      includeSearchBox: true,
    },
    findMenu: {
      option: 'Where To Buy',
      optionLink: '#/findStore',
      external: false,
    },
  },
  footerSection: {
    copyRight: '© 2018 | Georgia Pacific',
    footerColumnLength: 4,
  },
  commonTitles: {
    iconCloseTitle: 'Close',
  },
  slider: {
    iconChevronLeftTitle: 'Left',
    iconChevronRightTitle: 'Right',
  },
};

export default common;
