/**
 * Analytics Service provides multiple methods for analytics tracking.
 * Tracking methods trigger required details to Google analytics service
 */

import GoogleAnalyticsService from './google-analytics-service';

const googleAnalyticsService = new GoogleAnalyticsService();

class AnalyticsService {
  /**
   * Track product impressions
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackImpressions = (dataObj, callback) => {
    let impressions = [];
    let currencyCode = '';

    if (dataObj && dataObj.price) {
      currencyCode = dataObj.price.currencyIso; // Local currency
      impressions = [
        {
          name: dataObj.name,
          id: dataObj.code,
          price: dataObj.price.value,
          brand: '',
          category: '',
          variant: '',
          list: '',
          position: '',
        },
      ];
      googleAnalyticsService.trackImpressions(
        impressions,
        currencyCode,
        callback,
      );
    }
  };

  /**
   * Track product detail impressions
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
  trackDetailImpressions = (dataObj, callback) => {
    let impressions = [];

    if (dataObj && dataObj.price) {
      impressions = [
        {
          name: dataObj.name,
          id: dataObj.code,
          price: dataObj.price.value,
          brand: '',
          category: '',
          variant: '',
        },
      ];
      googleAnalyticsService.trackDetailImpressions(impressions, callback);
    }
  };

  /**
   * Track search results information
   * @param  {object}   dataObj contains search term and total result
   * @return {object}          null
   */
  trackSearchResults = (dataObj, callback) => {
    let productsSearched = {};

    if (dataObj && dataObj.pagination) {
      productsSearched = {
        searchTerm: dataObj.freeTextSearch,
        totalSearchResults: dataObj.pagination.totalResults,
      };
      googleAnalyticsService.trackSearchResults(productsSearched, callback);
    }
  };

  /**
   * Track add to cart products related information
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
  trackAddToCart = (dataObj, callback) => {
    let productAdded = {};

    if (dataObj) {
      productAdded = {
        products: [
          {
            id: dataObj.code,
            name: dataObj.name,
            quantity: dataObj.quantity,
          },
        ],
      };
      googleAnalyticsService.trackAddToCart(productAdded, callback);
    }
  };

    /**
   * Track View Product Detail Page - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
    trackViewItemGA4 = (dataObj, callback) => {
      let impressions = [];
      let currencyCode = '';
      let price = '';
      if (dataObj) {
        currencyCode = dataObj.price.currencyIso;
        price = dataObj.price.value;
        impressions = [
          {
            item_name: dataObj.name,
            item_id: dataObj.code,
            price: dataObj.price.value,
            item_brand: dataObj.subbrand,
            item_category: dataObj.categories[0].name,
            currency: dataObj.price.currencyIso,
          },
        ];
        googleAnalyticsService.trackViewItemGA4(
          impressions,
          currencyCode,
          price,
          callback,
        );
      }
    };

  /**
   * Track add to cart products related information - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
  trackAddToCartGA4 = (dataObj, callback) => {
    let productAdded = {};
    let currencyCode = '';
    let price = '';

    if (dataObj) {
      currencyCode = dataObj.currencyCode;
      price = dataObj.price;
      productAdded = {
        products: [
          {
            item_id: dataObj.code,
            item_name: dataObj.name,
            price: dataObj.price,
            item_brand: dataObj.subBrand,
            item_category: dataObj.category,
            quantity: dataObj.quantity,
          },
        ],
      };
      googleAnalyticsService.trackAddToCartGA4(productAdded, currencyCode, price, callback);
    }
  };

    /**
   * Track selected item/product related information - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
    trackSelectItemGA4 = (dataObj, callback) => {
      let productAdded = {};
      let currencyCode = '';
      let price = '';
      if (dataObj) {
        currencyCode = dataObj.currencyCode;
        price = dataObj.price;
        productAdded = {
          products: [
            {
              item_id: dataObj.code,
              item_name: dataObj.name,
              price: dataObj.price,
              quantity: dataObj.quantity,
            },
          ],
        };
        googleAnalyticsService.trackSelectItemGA4(productAdded, currencyCode, price, callback);
      }
    };

    /**
   * Track user login related information - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
    trackLoginGA4 = (loginMethod, callback) => {
      let methodName = '';
      if (loginMethod) {
        methodName = loginMethod;
        googleAnalyticsService.trackLoginGA4(methodName, callback);
      }
    };

    /**
   * Track user registration related information - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
    trackSignupGA4 = (signupMethod, callback) => {
      let methodName = '';
      if (signupMethod) {
        methodName = signupMethod;
        googleAnalyticsService.trackSignupGA4(methodName, callback);
      }
    };

    /**
   * Track search query related information - GA4
   * @param  {object}   dataObj contains details of product during checkout
   * @return {object}          null
   */
    trackSearchGA4 = (dataObj, callback) => {
      let itemListName = '';
      if (dataObj) {
        itemListName = dataObj.currentQuery.query.value;
        googleAnalyticsService.trackSearchGA4(itemListName, callback);
      }
    };

    /**
   * Track selected content/product related information - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
    trackSelectContentGA4 = (dataObj, callback) => {
      let contentType = '';
      let contentId = '';
      if (dataObj) {
        contentType = 'product';
        contentId = dataObj.code;
        googleAnalyticsService.trackSelectContentGA4(contentType, contentId, callback);
      }
    };

    /**
   * Track shared products related information - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
    trackShareGA4 = (dataObj, callback) => {
      let shareMethod = '';
      let contentType = '';
      let itemId = '';
      if (dataObj) {
        shareMethod = dataObj.method;
        contentType = dataObj.contentType;
        itemId = dataObj.itemId;
        googleAnalyticsService.trackShareGA4(shareMethod, contentType, itemId, callback);
      }
    };

  /**
   * Track add to wishlist products related information - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
  trackAddToWishListGA4 = (dataObj, callback) => {
    let productAdded = {};
    let currencyCode = '';
    let price = '';

    if (dataObj) {
      currencyCode = dataObj.currencyCode;
      price = dataObj.price;
      productAdded = {
        products: [
          {
            item_id: dataObj.code,
            item_name: dataObj.name,
            price: dataObj.price,
            item_brand: dataObj.subBrand,
            item_category: dataObj.category,
          },
        ],
      };
      googleAnalyticsService.trackAddToWishListGA4(productAdded, currencyCode, price, callback);
    }
  };

    /**
   * Track add to wishlist products related information from cart page - GA4
   * @param  {object}   dataObj contains details of product added to cart
   * @return {object}          null
   */
    trackAddToWishListGA4Cart = (dataObj, callback) => {
      let productAdded = {};
      let currencyCode = '';
      let price = '';
      const products = [];
      if (dataObj) {
        currencyCode = dataObj.shoppingCartData.totalPriceWithTax.currencyIso;
        price = dataObj.shoppingCartData.totalPriceWithTax.value;
        dataObj.shoppingCartData.deliveryOrderGroups[0].entries.forEach((product) => {
          products.push({
            item_name: product.product.name,
            item_id: product.product.code,
            price: product.basePrice.value,
            item_brand: product.product.subbrand,
            quantity: product.quantity,
          });
        });
        productAdded = {
          products,
        };
        googleAnalyticsService.trackAddToWishListGA4(productAdded, currencyCode, price, callback);
      }
    };

    /**
   * Track View cart page - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
    trackViewCartGA4 = (dataObj, callback) => {
      let productAdded = {};
      let currencyCode = '';
      let value = '';
      const products = [];

      if (dataObj) {
        currencyCode = dataObj.totalPriceWithTax.currencyIso;
        value = dataObj.totalPriceWithTax.value;
        dataObj.deliveryOrderGroups[0].entries.forEach((product) => {
          products.push({
            item_name: product.product.name,
            item_id: product.product.code,
            price: product.basePrice.value,
            item_brand: product.product.subbrand,
            quantity: product.quantity,
          });
        });
        productAdded = {
          products,
        };
        googleAnalyticsService.trackViewCartGA4(productAdded, currencyCode, value, callback);
      }
    };

    /**
   * Track remove from cart - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
    trackRemoveFromCartGA4 = (dataObj, callback) => {
      let productRemoved = {};
      let currencyCode = '';
      let value = '';
      if (dataObj) {
        currencyCode = dataObj.currencyCode;
        value = dataObj.price;
        productRemoved = {
          products: [
            {
              item_name: dataObj.name,
              item_id: dataObj.code,
              quantity: dataObj.quantity,
              price: dataObj.price,
            },
          ],
        };
        googleAnalyticsService.trackRemoveFromCartGA4(productRemoved, currencyCode, value, callback);
      }
    };

      /**
   * Track clear cart from cart page - GA4
   * @param  {object}   dataObj contains product details for PDP
   * @return {object}          null
   */
      trackRemoveFromCartGA4Cart = (dataObj, callback) => {
        let productRemoved = {};
        let currencyCode = '';
        let value = '';
        const products = [];
        if (dataObj) {
          currencyCode = dataObj.totalPriceWithTax.currencyIso;
          value = dataObj.totalPriceWithTax.value;
          dataObj.deliveryOrderGroups[0].entries.forEach((product) => {
            products.push({
              item_name: product.product.name,
              item_id: product.product.code,
              price: product.basePrice.value,
              item_brand: product.product.subbrand,
              quantity: product.quantity,
            });
          });
          productRemoved = {
            products,
          };
          googleAnalyticsService.trackRemoveFromCartGA4(productRemoved, currencyCode, value, callback);
        }
      };

    /**
   * Track begin checkout - GA4
   * @param  {object}   dataObj contains details of product during checkout
   * @return {object}          null
   */
    trackBeginCheckoutGA4 = (dataObj, callback) => {
      let checkoutProducts = {};
      let currencyCode = '';
      let value = '';
      const products = [];

      if (dataObj) {
        currencyCode = dataObj[0].basePrice.currencyIso;
        value = dataObj.reduce((a, b) => a + b.basePrice.value, 0);
        dataObj.forEach((product) => {
          products.push({
            item_name: product.product.name,
            item_id: product.product.code,
            price: product.basePrice.value,
            item_brand: product.product.subbrand,
            quantity: product.quantity,
          });
        });
        checkoutProducts = {
          products,
        };
        googleAnalyticsService.trackBeginCheckoutGA4(checkoutProducts, currencyCode, value, callback);
      }
    };

    /**
   * Track shipping - GA4
   * @param  {object}   dataObj contains details of product during checkout
   * @return {object}          null
   */
    trackShippingGA4 = (dataObj, callback) => {
      let productAdded = {};
      let currencyCode = '';
      let value = '';
      let couponCode = '';
      let shippingTier = [];
      const products = [];
      if (dataObj && dataObj.cartDetails.totalPriceWithTax.currencyIso !== undefined) {
        currencyCode = dataObj.cartDetails.totalPriceWithTax.currencyIso;
        value = dataObj.cartDetails.totalPriceWithTax.value;
        if (dataObj.cartDetails.appliedOrderPromotions.length !== 0) {
          couponCode = dataObj.cartDetails.appliedOrderPromotions[0].appliedCouponCodes[0];
        } else if (dataObj.cartDetails.appliedProductPromotions.length !== 0) {
          couponCode = dataObj.cartDetails.appliedProductPromotions[0].appliedCouponCodes[0];
        } else {
          couponCode = '';
        }
        if (dataObj.deliverymodes.deliveryGroup) {
          dataObj.deliverymodes.deliveryGroup.forEach((entries) => {
            shippingTier.push({
              shipping_tiername: entries.value.deliveryModes.deliveryModes[0].code,
            });
          });
        }
        if (dataObj.deliverymodes.deliveryModes) {
          shippingTier = dataObj.deliverymodes.deliveryModes[0].code;
        }
        dataObj.cartDetails.deliveryOrderGroups[0].entries.forEach((product) => {
          products.push({
            item_name: product.product.name,
            item_id: product.product.code,
            price: product.basePrice.value,
            item_brand: product.product.subbrand,
            quantity: product.quantity,
          });
        });
        productAdded = {
          products,
        };
        googleAnalyticsService.trackShippingGA4(productAdded, currencyCode, value, couponCode, shippingTier, callback);
      }
    };

    /**
   * Track Payment - GA4
   * @param  {object}   dataObj contains details of product during checkout
   * @return {object}          null
   */
    trackAddPaymentInfoGA4 = (dataObj, callback) => {
      let productAdded = {};
      let currencyCode = '';
      let value = '';
      let couponCode = '';
      const products = [];

      if (dataObj) {
        currencyCode = dataObj.totalPriceWithTax.currencyIso;
        value = dataObj.totalPriceWithTax.value;
        if (dataObj.appliedOrderPromotions.length !== 0) {
          couponCode = dataObj.appliedOrderPromotions[0].appliedCouponCodes[0];
        } else if (dataObj.appliedProductPromotions.length !== 0) {
          couponCode = dataObj.appliedProductPromotions[0].appliedCouponCodes[0];
        } else {
          couponCode = '';
        }
        dataObj.deliveryOrderGroups[0].entries.forEach((product) => {
          products.push({
            item_name: product.product.name,
            item_id: product.product.code,
            price: product.basePrice.value,
            item_brand: product.product.subbrand,
            quantity: product.quantity,
          });
        });
        productAdded = {
          products,
        };
        googleAnalyticsService.trackAddPaymentInfoGA4(productAdded, currencyCode, value, couponCode, callback);
      }
    };

      /**
   * View items list - GA4
   * @param  {object}   dataObj contains details of product during checkout
   * @return {object}          null
   */
      trackViewItemsListGA4 = (dataObj, callback) => {
        let productAdded = {};
        let itemListId = '';
        let itemListName = '';
        let totalSearchResults = '';
        const products = [];

        if (dataObj) {
          itemListId = dataObj.currentQuery.url;
          itemListName = dataObj.currentQuery.query.value;
          totalSearchResults = dataObj.pagination.totalResults;
          dataObj.products.forEach((product) => {
            products.push({
              item_name: product.name,
              item_id: product.code,
              price: product.price.value,
              item_brand: product.subbrand,
            });
          });
          productAdded = {
            products,
          };
          googleAnalyticsService.trackViewItemsListGA4(productAdded, itemListId, itemListName, totalSearchResults, callback);
        }
      };

    /**
       * Track purchase - GA4
       * @param  {object}   dataObj contains details of product during checkout
       * @return {object}          null
       */
    trackProductPurchasedGA4 = (dataObj, callback) => {
      let productAdded = {};
      const products = [];
      const orderPromotions = [];
      let transactionId = '';
      let affiliationValue = '';
      let totalValue = '';
      let taxAmount = '';
      let shippingCost = '';
      let currencyIso = '';
      let couponCode = '';

      if (dataObj) {
        transactionId = dataObj.code;
        affiliationValue = '';
        totalValue = dataObj.totalPrice.value;
        taxAmount = dataObj.totalTax.value;
        shippingCost = dataObj.deliveryCost ? dataObj.deliveryCost.value : 0;
        currencyIso = dataObj.totalPrice.currencyIso;
        couponCode = orderPromotions.toString();
        productAdded = {
          products,
        };
        if (dataObj.appliedOrderPromotions) {
          dataObj.appliedOrderPromotions.forEach((promotion) => {
            promotion.appliedCouponCodes.forEach((coupon) => {
              orderPromotions.push(coupon);
            });
          });
        }
        dataObj.entries.forEach((product) => {
          const productPromotions = [];
          if (dataObj.appliedProductPromotions) {
            dataObj.appliedProductPromotions.forEach((promotion) => {
              productPromotions.push(promotion.promotion.code);
            });
          }
          products.push({
            item_name: product.product.name,
            item_id: product.product.code,
            price: product.basePrice.value,
            item_brand: product.product.subbrand,
            quantity: product.quantity,
            coupon: productPromotions.toString(),
          });
        });
        googleAnalyticsService.trackProductsPurchasedGA4(productAdded, transactionId, affiliationValue, totalValue, taxAmount, shippingCost, currencyIso, couponCode, callback);
      }
    };

  /**
   * Track product removed from the cart related information
   * @param  {object}   dataObj contains details of product removed from cart
   * @return {object}          null
   */
  trackRemoveFromCart = (dataObj, callback) => {
    let productRemoved = {};

    if (dataObj) {
      productRemoved = {
        products: [
          {
            id: dataObj.code,
            name: dataObj.name,
            quantity: dataObj.quantity,
          },
        ],
      };
      googleAnalyticsService.trackRemoveFromCart(productRemoved, callback);
    }
  };

  /**
   * Track products purchased related information
   * @param  {object}   transactionObj contains details of the transaction
   * @param  {object}   dataObj contains details of product purchased
   * @return {object}          null
   */
  trackProductPurchased = (dataObj, callback) => {
    let productPurchased = {};
    const products = [];
    const orderPromotions = [];

    if (dataObj) {
      if (dataObj.appliedOrderPromotions) {
        dataObj.appliedOrderPromotions.forEach((promotion) => {
          promotion.appliedCouponCodes.forEach((coupon) => {
            orderPromotions.push(coupon);
          });
        });
      }
      dataObj.entries.forEach((product) => {
        const productPromotions = [];
        if (dataObj.appliedProductPromotions) {
          dataObj.appliedProductPromotions.forEach((promotion) => {
            productPromotions.push(promotion.promotion.code);
          });
        }
        products.push({
          name: product.product.name,
          id: product.product.code,
          price: product.basePrice.value,
          brand: '',
          category: '',
          variant: '',
          quantity: product.quantity,
          coupon: productPromotions.toString(),
        });
      });
      productPurchased = {
        actionField: {
          id: dataObj.code, // Transaction ID. Required for purchases and refunds.
          affiliation: '',
          revenue: dataObj.totalPrice.value, // Total transaction value (incl. tax and shipping)
          tax: dataObj.totalTax.value,
          shipping: dataObj.deliveryCost ? dataObj.deliveryCost.value : 0,
          coupon: orderPromotions.toString(),
        },
        products,
      };
      googleAnalyticsService.trackProductsPurchased(productPurchased, callback);
    }
  };

  /**
   * Track checkout related information
   * @param  {object}   dataObj contains details of product during checkout
   * @return {object}          null
   */
  trackCheckout = (dataObj, callback) => {
    let checkoutProducts = {};
    const products = [];

    if (dataObj) {
      dataObj.forEach((product) => {
        products.push({
          name: product.product.name,
          id: product.product.code,
          price: product.basePrice.value,
          brand: '',
          category: '',
          variant: '',
          quantity: product.quantity,
          coupon: '',
        });
      });
      checkoutProducts = {
        products,
      };
      googleAnalyticsService.trackCheckout(checkoutProducts, callback);
    }
  };

  /**
   * Track marketing form post related information
   * @param  {object}   dataObj contains details of form post
   * @return {object}          null
   */
  trackMarketingFormPost = (dataObj, callback) => {
    googleAnalyticsService.trackMarketingFormPost(dataObj, callback);
  };
}

// export default AnalyticsService;
export default AnalyticsService;
